import React, { useState, useEffect, useRef } from "react";
import { inject, observer } from "mobx-react";
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import _ from "lodash";
import {
  PageHeader,
  Tag,
  Button,
  Statistic,
  Descriptions,
  Row,
  Col,
  Card,
  Table,
  Tabs,
  Modal,
  Form,
  Input,
  Tooltip,
  Checkbox,
  Select,
  message,
  Space, DatePicker
} from 'antd';
import RouteName from "../../../RouteNames";
import moment from "moment";
import {CloseCircleTwoTone} from "@ant-design/icons";
import ReactMarkdown from "react-markdown";

const { Column, ColumnGroup } = Table;
const { RangePicker } = DatePicker;

const initialValues = { startDate: moment().subtract(1, 'month'), endDate: moment() }
const ShopVisit = ({ categoryStore, siteStore }) => {
  const [statics, setStatics] = useState([]);
  const [filter, setFilter] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [detailDate, setDetailDate] = useState();

  useEffect(() => {
    categoryStore.loadCategories();
    siteStore.loadSite();
    loadStatics()
  }, [])

  const loadStatics = () => {
    setLoading(true)

    const siteId = siteStore.site.id;
    const start = filter.startDate.toISOString()
    const end = filter.endDate.toISOString()
    const uri = `/sites/${siteId}/conversions?start=${start}&end=${end}`

    axios.get(uri).then(res => {
      setLoading(false)

      const statics = res.data;
      _.forEach(statics, (row) => {
        const { paidCount, freeCount, revisitCount } = row;
        row.totalCount = paidCount + freeCount + revisitCount;
      })

      setStatics(statics)
    });
  }

  const setFilterDateRange = period => {
    let startDate = undefined;
    let endDate = undefined;

    if(period === "오늘") {
      startDate = moment();
      endDate = moment();
    } else if(period === "어제") {
      startDate = moment().subtract(1, 'day');
      endDate = moment().subtract(1, 'day');
    } else if(period === "최근 일주일") {
      startDate = moment().subtract(6, 'day');
      endDate = moment();
    } else if(period === "최근 1달") {
      startDate = moment().subtract(1, 'month');
      endDate = moment();
    } else if(period === "이번 달") {
      startDate = moment().startOf("month");
      endDate = moment().endOf("month");
    } else if(period === "저번 달") {
      startDate = moment().subtract(1, "month").startOf("month");
      endDate = moment().subtract(1, "month").endOf("month");
    }

    setFilter({ ...filter, startDate, endDate });
  }

  if(!categoryStore.categories || !siteStore.site)
    return null

  return (
    <div className="ShopVisit">
      <Row gutter={20}>
        <Col xs={{ offset: 2, span: 20 }}>
          <Card>
            <>
              <Row gutter={[20, 10]}>
                <Col xs={2}>
                  기간
                </Col>
                <Col xs={22}>
                  <Space>
                    <RangePicker
                      // onChange={onFilterDateChanged}
                      value={[filter.startDate, filter.endDate]}
                    />
                    { ["오늘", "어제", "최근 일주일", "최근 1달", "이번 달", "저번 달"].map(period => (
                      <Button
                        key={period}
                        onClick={() => setFilterDateRange(period)}
                      >
                        {period}
                      </Button>
                    ))}
                  </Space>
                </Col>
              </Row>
              <Row gutter={[20, 60]}>
                <Col offset={2}>
                  <Space>
                    <Button
                      type="primary"
                      onClick={() => loadStatics()}
                    >
                      검색
                    </Button>
                  </Space>
                </Col>
              </Row>
            </>
            <Table
              dataSource={statics}
              loading={loading}
            >
              <Column
                title="날짜"
                dataIndex="date"
                key="date"
              />
              <Column title="총 전환" dataIndex="totalCount" key="totalCount" />
              <Column title="유료 전환" dataIndex="paidCount" key="paidCount"/>
              <Column title="무료 전환" dataIndex="freeCount" key="freeCount"/>
              <Column title="재방문" dataIndex="revisitCount" key="revisitCount"/>
              <Column
                dataIndex="revisitCount"
                key="buttons"
                width={100}
                render={(tag, row) => {
                  return (
                    <Button onClick={() => setDetailDate(row.date)}>
                      상세보기
                    </Button>
                  )
                }}
              />
            </Table>
          </Card>
        </Col>
      </Row>
      { detailDate && (
        <DetailModal
          siteId={siteStore.site.id}
          date={detailDate}
          onCancel={() => setDetailDate(undefined)}
        />
      )}
    </div>
  )
};

const DetailModal = ({ siteId, date, onCancel }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    loadDateConversions()
  }, [])

  const loadDateConversions = () => {
    setLoading(true)

    const uri = `/sites/${siteId}/conversions/${date}`
    axios.get(uri).then(res => {
      setLoading(false)

      const data = res.data;
      setData(data)
    });
  }

  return (
    <Modal
      title={`${date} 방문/전환`}
      visible={true}
      width="1200px"
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={() => {}}>
          닫기
        </Button>
      ]}
    >
      <Table
        dataSource={data}
        loading={loading}
      >
        <Column
          title="유저ID"
          dataIndex="userIdentity"
          key="userIdentity"
          width={220}
        />
        <Column title="진입URL" dataIndex="url" key="url" ellipsis />
        <Column
          title="과금"
          dataIndex="paid"
          key="paid"
          width={100}
          render={(paid) => paid ? "O" : "X"}
        />
        <Column title="IP" dataIndex="ip" key="ip" width={120} />
        <Column
          title="시간"
          dataIndex="dateCreate"
          key="dateCreate"
          width={200}
          render={(isoDate) => {
            return moment(isoDate).format("yyyy-MM-DD hh:mm:ss")
          }}
        />
      </Table>
    </Modal>
  );
}

export default inject("categoryStore", "siteStore")(observer(ShopVisit));