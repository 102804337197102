import React, { useState, useEffect, useRef } from "react";
import { inject, observer } from "mobx-react";
import {useParams} from "react-router-dom";
import axios from "axios";
import _ from "lodash";
import {
  PageHeader,
  Tag,
  Button,
  Statistic,
  Descriptions,
  Row,
  Col,
  Card,
  Table,
  Tabs,
  Modal,
  Form,
  Input,
  Tooltip,
  Checkbox,
  Select,
  message,
  Space, Divider, Typography
} from 'antd';
import {QuestionCircleFilled} from "@ant-design/icons";
import MaxLength, { shouldUpdate } from "../../molecules/MaxLength";

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 16 },
};

const ShopSetting = ({ siteStore }) => {
  const [form] = Form.useForm();
  const [companyInfo, setCompanyInfo] = useState(undefined);
  const [introduce, setIntroduce] = useState("");
  const [introduceEn, setIntroduceEn] = useState("");

  useEffect(() => {
    loadCompanyInfo();
  }, [])


  useEffect(() => {
    init();
  }, [companyInfo])

  const loadCompanyInfo = () => {
    return axios.get(`/partner/${siteStore.siteId}/company`).then(res => {
      const companyInfo = res.data;
      setCompanyInfo(companyInfo);

      return res;
    });
  }

  const init = () => {
    if(!companyInfo) {
      return null
    }

    const site = siteStore.site;
    const setting = site.setting;

    const introduce = site.introduce || ""
    const introduceEn = site.introduceEn || ""

    form.setFieldsValue({
      shopName: site.name,
      shopNameEn: site.nameEn,
      shopUrl: site.domain,
      introduce,
      introduceEn,
      corpName: companyInfo.companyName,
      corpRegNumber: companyInfo.registrationNumber,
      corpPresident: companyInfo.president,
      corpAddress: companyInfo.address,
      contactName: companyInfo.contactName,
      contactPhone: companyInfo.contactPhone,
      contactEmail: companyInfo.contactEmail,
      freeshipping: site.freeshipping,
      shippingPrice: site.shippingPrice,
      freeshippingPrice: site.freeshippingPrice,
      sleepPerRequest: setting.sleepPerRequest
    });

    setIntroduce(introduce)
    setIntroduceEn(introduceEn)
  }

  const submit = () => {
    const values = {...form.getFieldsValue()};

    axios.put(`/partner/shops/${siteStore.siteId}/settings`, values).then(() => {
      window.location.reload(false);
    }).catch(err => {
      message.error("저장 실패");
    })
  }

  const onChangeCheckbox = event => {
    const name = event.target.name;
    const value = event.target.value;

    if(name === "freeshipping") {
      form.setFieldsValue({
        freeshipping: value
      });
    }

  }

  const checkLengthAndUpdate = (event, setter, max) => {
    event.preventDefault()
    event.stopPropagation()

    console.log(event, event.target.value);

    const value = event.target.value
    if(shouldUpdate(max, value))
      setter(value)
  }

  if(!siteStore.site || !companyInfo)
    return null

  return (
    <div>
      <Row gutter={20}>
        <Col
          xxl={{ offset: 6, span: 12 }}
          xl={{ offset: 3, span: 18 }}
          lg={{ offset: 2, span: 20 }}
          md={24}
        >
          <Card>
            <Form {...layout} form={form} name="control-ref" onFinish={undefined}>
              <Divider orientation="left">쇼핑몰 기본 정보</Divider>
              <Form.Item name="shopName" label="쇼핑몰명">
                <Input disabled className="static" />
              </Form.Item>
              <Form.Item name="shopNameEn" label="(해외노출)영문 쇼핑몰명">
                <Input disabled className="static" />
              </Form.Item>
              <Form.Item name="shopUrl" label="쇼핑몰 URL">
                <Input
                  placeholder="https://example.com"
                  disabled
                  className="static"
                />
              </Form.Item>
              <Form.Item name="introduce" label="쇼핑몰소개" wrapperCol={{ span: 14 }} extra={(
                <MaxLength max={32} value={introduce} />
              )}>
                <Input value={introduce} maxLength={32} onChange={e => setIntroduce(e.target.value)} />
              </Form.Item>
              <Form.Item name="introduceEn" label="(해외노출)영문 쇼핑몰소개" wrapperCol={{ span: 14 }} extra={(
                <MaxLength max={50} value={introduceEn} />
              )}>
                <Input value={introduceEn} maxLength={50} onChange={e => setIntroduceEn(e.target.value)} />
              </Form.Item>
              <Divider orientation="left" style={{ marginTop: "48px" }}>
                사업자 정보
              </Divider>
              <Form.Item name="corpName" label="사업자명" wrapperCol={{ span: 8 }}>
                <Input disabled className="static" />
              </Form.Item>
              <Form.Item name="corpRegNumber" label="사업자 등록번호" wrapperCol={{ span: 6 }}>
                <Input disabled className="static" />
              </Form.Item>
              <Form.Item name="corpPresident" label="대표자" wrapperCol={{ span: 4 }}>
                <Input disabled className="static" />
              </Form.Item>
              <Form.Item name="corpAddress" label="주소지" wrapperCol={{ span: 14 }}>
                <Input />
              </Form.Item>
              <Divider orientation="left" style={{ marginTop: "48px" }}>
                담당자 정보
              </Divider>

              <Text type="secondary">올바른 안내를 위해 담당자 정보를 항상 최신화해주시기 바랍니다.</Text>
              <Form.Item name="contactName" label="성함" wrapperCol={{ span: 4 }} style={{ marginTop: "16px" }}>
                <Input />
              </Form.Item>
              <Form.Item name="contactPhone" label="핸드폰번호" wrapperCol={{ span: 7 }}>
                <Input />
              </Form.Item>
              <Form.Item name="contactEmail" label="이메일" wrapperCol={{ span: 7 }}>
                <Input />
              </Form.Item>
              <Divider orientation="left" style={{ marginTop: "48px" }}>
                배송금액 설정
              </Divider>
              <Form.Item
                name="freeshipping"
                label="전체 상품 무료배송 여부"
                valuePropName="checked"
              >
                <Checkbox
                  onChange={onChangeCheckbox}
                >
                  전상품 무료배송
                </Checkbox>
              </Form.Item>
              <Form.Item name="shippingPrice" label="배송비" wrapperCol={{ span: 4 }}>
                <Input />
              </Form.Item>
              <Form.Item name="freeshippingPrice" label="무료배송 최소금액" wrapperCol={{ span: 4 }}>
                <Input />
              </Form.Item>
              <Divider orientation="left" style={{ marginTop: "48px" }}>
                스크래핑 설정
              </Divider>
              <Form.Item name="sleepPerRequest" label={(
                <>
                  {"스크래핑 페이지 접속 간격 "}
                </>
              )} wrapperCol={{ span: 4 }}>
                <Input
                  suffix={
                    <Tooltip
                      placement="topLeft"
                      title={(
                        <>
                          <span>스크래핑 시 쇼핑몰에 가해지는 트래픽이 부담 될 경우 값을 높혀 부담을 줄일 수 있습니다.</span>
                          <br />
                          <span>(권장: 500~1000, 최대: 2000)</span>
                        </>
                      )}
                      arrowPointAtCenter
                      style={{ maxWidth: "350px" }}
                    >
                      <QuestionCircleFilled />
                    </Tooltip>
                  }
                />
              </Form.Item>
              <Divider orientation="left" style={{ marginTop: "48px" }} />
              <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                <Button type="primary" onClick={submit}>
                  저장
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  )
};

export default inject("siteStore")(observer(ShopSetting));