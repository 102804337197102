import { observable, action } from "mobx";
import axios from "axios";
import _ from "lodash";

class SiteStore {
  @observable siteId = undefined;
  @observable site = undefined;

  @action
  handleOwnShops = shops => {
    const defaultShop = shops[0];

    this.siteId = defaultShop.id;
    this.site = defaultShop;

    console.log("this.setShop", defaultShop)
  }

  @action
  loadSite = () => {
    if (!this.site) {
      return this.loadSiteForce();
    }

    return new Promise(resolve => {
      resolve()
    });
  };

  @action
  loadSiteForce() {
    return axios.get(`/admin/sites/${this.siteId}`).then(res => {
      const { site } = res.data;
      this.site = site;

      return res;
    });
  };

}

export default new SiteStore();