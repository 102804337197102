import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'mobx-react'
import rootStore from "./store/RootStore";
import "antd/dist/antd.css";
import "reset-css";
import "./style/style.scss";

ReactDOM.render(
  <Provider {...rootStore}>
    <App />
  </Provider>
  ,
  document.getElementById('root')
);