import React from "react";
import {LineChartOutlined} from "@ant-design/icons";
import RouteName from "./RouteNames";

const admin = {
  title: (
    <span>
      <LineChartOutlined />
      어드민
    </span>
  ),
  adminOnly: true,
  items: {
    "/dashboard": "대시보드",
    "/sites": "사이트 리스트",
    "/categories": "카테고리 리스트",
    "/categoryTree": "카테고리 트리",
    "/new-store": "스토어 추가",
    "/accounts": "계정 관리",
  }
}

const shoppingmall = {
  title: (
    <span>
      <LineChartOutlined />
      쇼핑몰
    </span>
  ),
  items: {
    "/shop": "상품 수집",
    [RouteName.shopItems]: "상품 관리",
    "/shop/categories": "카테고리 관리",
    [RouteName.visits] : "방문/전환",
    "/shop/setting": "쇼핑몰 설정",
    "/shop/support": "서비스 지원",
  }
}

const menus = {
  admin,
  shoppingmall,
};

export default menus;