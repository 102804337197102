import React from "react";
import {Link, useLocation} from "react-router-dom";
import {Menu, Button} from "antd";
import menus from "../../../Menus";
import loginStore from "../../../store/LoginStore";


const {SubMenu} = Menu;

const LNB = ({}) => {
  const location = useLocation();
  const { pathname } = location;

  const menuKeys = Object.keys(menus);
  const isRoot = loginStore.isRoot();

  return (
    <Menu
      mode="inline"
      defaultOpenKeys={menuKeys}
      defaultSelectedKeys={[pathname]}
      style={{height: '100%', borderRight: 0}}
    >
      { menuKeys.map(key => {
        const menu = menus[key];
        const subMenus = menu.items;
        const adminOnly = menu.adminOnly;
        const subKeys = Object.keys(subMenus);

        if(adminOnly && !isRoot)
          return null;

        return (
          <SubMenu
            key={key}
            title={menu.title}
          >
            { subKeys.map(subKey => {
              const subName = subMenus[subKey];
              return (
                <Menu.Item key={subKey}>
                  <Link to={subKey}>
                    {subName}
                  </Link>
                </Menu.Item>
              )
            })}
          </SubMenu>
        )
      })}
    </Menu>
  )
};

export default LNB;