import React from "react";

const MaxLength = ({ max, value}) => {
  const curr = getLength(value)
  return (
    <div style={{ textAlign: "right" }}>
      {curr}/{max}
    </div>
  )
};

export const shouldUpdate = (max, value) => {
  const curr = getLength(value)
  return curr < max
}


const getLength = value => {
  if(!value)
    return 0

  return value.length
}

export default MaxLength