import React, {useEffect, useRef, useState} from "react";
import {Button, Checkbox, Col, Divider, Form, Input, message, Modal, Row, Select, Space, Spin, Typography} from "antd";
import axios from "axios";
import _ from "lodash";
import siteStore from "../../../store/SiteStore";
import { fullUrl } from "../../../util/S3";
import "./PartnerItemDetailModal.scss";

const { Title } = Typography;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 18,
    },
  },
};

const PartnerItemDetailModal = ({ selectedItem, visible, setVisible, onOk }) => {
  const [form] = Form.useForm();
  const [item, setItem] = useState({});
  const [notIgnoredImages, setNotIgnoredImages] = useState([]);
  const [displayingImages, setDisplayingImages] = useState([]);
  const [hidingImages, setHidingImages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("PartnerItemDetailModal", selectedItem)

    setItem(selectedItem);
    form.setFieldsValue(selectedItem);
    refreshNotIgnoredImages();

    loadItem();
  }, []);

  useEffect(var1 => {
    console.log("var1:", var1)
    refreshNotIgnoredImages();
  }, [item])

  const loadItem = () => {
    axios.get(`/partner/items/${selectedItem.id}`).then(res => {
      const loadedItem = res.data;
      console.log(loadedItem);
      setItem(loadedItem);
      setLoading(false);
    })
  }


  const refreshNotIgnoredImages = () => {
    const notIgnoredImages = _.filter(item.images, image => { return image.status === "DISPLAY" || image.status === "HIDE" });
    setNotIgnoredImages(notIgnoredImages);

    const displaying = _.filter(item.images, { status: "DISPLAY" })
    setDisplayingImages(displaying)

    const hiding = _.filter(item.images, { status: "HIDE" })
    setHidingImages(hiding)
  };


  const hide = imageId => {
    const url = `/admin/items/${item.id}/images/${imageId}/hide`;
    axios.patch(url).then(() => {
      findImageAndChangeStatus(imageId, "HIDE");
      refreshNotIgnoredImages();

    });
  };

  const display = imageId => {
    if(displayingImages.length >= 10) {
      message.error("최대 10개까지만 노출할 수 있습니다.")
      return
    }
    const url = `/admin/items/${item.id}/images/${imageId}/display`
    axios.patch(url).then(() => {
      findImageAndChangeStatus(imageId, "DISPLAY");
      refreshNotIgnoredImages()
    });
  };

  const findImageAndChangeStatus = (imageId, status) => {
    const filtered = _.filter(notIgnoredImages, image => {
      return image.itemImageId === imageId
    });

    filtered.forEach(image => {
      image.status = status;
    });
  };

  return (
    <Modal
      className="PartnerItemDetailModal"
      title="상품 상세보기"
      visible={visible}
      onCancel={() => setVisible(false)}
      width="680px"
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          닫기
        </Button>
      ]}
    >
      <Spin spinning={loading}>
        <Row gutter={[10, 20]}>
          <Col xs={24}>
            <Title level={4}>
              {item.title}
              <a href={`${siteStore.site.domain}${item.url}`} target="_blank">
                <Button type="link">열기</Button>
              </a>
            </Title>
          </Col>
          <Col xs={8}>
            <div
              className="card"
              style={{
              width: "200px",
              height: "266px",
              backgroundImage: `url('${fullUrl(item.thumbnail)}')`,
              borderRadius: "6px",
            }}>
            </div>
          </Col>
          <Col xs={16}>
            <Form
              {...formItemLayout}
              form={form}
              name="register"
              scrollToFirstError
            >
              <Form.Item
                name="id"
                label="ITEMID"
              >
                <Input className="static" />
              </Form.Item>
              <Form.Item
                name="itemCode"
                label="ITEMCODE"
              >
                <Input className="static" />
              </Form.Item>
              <Form.Item
                name="status"
                label="status"
              >
                <Input className="static" />
              </Form.Item>
              <Form.Item
                name="price"
                label="가격"
              >
                <Input className="static" />
              </Form.Item>
              <Form.Item
                name="discountPrice"
                label="할인가격"
              >
                <Input className="static" />
              </Form.Item>
              <Form.Item
                name="hasStock"
                label="재고"
                valuePropName="checked"
              >
                <Checkbox>있음</Checkbox>
              </Form.Item>
            </Form>
          </Col>
          <Col xs={24}>

            <Divider orientation="left">노출중 (최대10개)</Divider>
            <ImageList>
              { displayingImages.map((image1, index) => (
                <DraggableImageCard
                  image={image1}
                  index={index}
                  onClick={hide}
                  displaying={true}
                />
              ))}
            </ImageList>
          </Col>
          <Col xs={24}>
            <Divider orientation="left">비노출</Divider>
            <ImageList>
              { hidingImages.map((image1, index) => (
                <DraggableImageCard
                  image={image1}
                  index={index}
                  onClick={display}
                  displaying={false}
                />
              ))}
            </ImageList>
          </Col>
        </Row>
      </Spin>
    </Modal>
  )
};

const ImageList = ({ children }) => {
  return children
}

const DraggableImageCard = ({ image, onClick, index, displaying}) => {
  const className = displaying ? "card image image-display" : "card image image-hide"

  return (
    <ImageCard
      image={image}
      onClick={onClick}
      className={className}
    />
  )
}

class ImageCard extends React.Component {
  render() {
    const { image, onClick, className } = this.props;
    const url = fullUrl(image.url);
    console.log(image)
    return (
      <div
        className={className}
        onClick={() => onClick(image.itemImageId)}
        style={{
          backgroundImage: `url('${url}')`,
        }}
      />
    )
  }
}


export default PartnerItemDetailModal;