import React, {useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
import {Col, Row, Input, Button, message} from "antd";
import axios from "axios";
import copy from 'copy-to-clipboard';
import _ from "lodash";
import "./CategoryTree.scss";

const CategoryTree = ({ categoryStore }) => {
  const [textarea, setTextarea] = useState("")
  useEffect(() => {
    categoryStore.loadCategories();
  }, []);

  const categories = categoryStore.categories
  const categoryTree = categoryStore.categoryTree
  const maped = _.keyBy(categoryTree, "id")
  const renderNode = (node, depth) => {
    return (
      <div className={`node depth-${depth}`}>
        <div onClick={() => { copy(node.id) }} className="title">
          {node && node.title}
        </div>
        { node && node.children && node.children.map(child => renderNode(child, depth + 1)) }
      </div>
    )
  }

  if(!categoryStore.isReady())
    return null;

  const onSubmit = () => {
    const params = { fulltext: textarea }

    axios.post(`/admin/categories/bulk`, params).then(res => {
      message.success("성공")
    }).catch(() => {
      message.error("실패")
    })

  }

  return (
    <div className="CategoryTree">
      <Row>
        <Col xs={4}>
          <div className="node-wrap">
            { renderNode(maped[2000000], 0)}
            { renderNode(maped[4000000], 0)}
          </div>
        </Col>
        <Col xs={4}>
          <div className="node-wrap">
            { renderNode(maped[5000000], 0)}
            { renderNode(maped[6000000], 0)}
          </div>
        </Col>
        <Col xs={4}>
          <div className="node-wrap">
            { renderNode(maped[3000000], 0)}
            { renderNode(maped[7000000], 0)}
            { renderNode(maped[8000000], 0)}
            { renderNode(maped[13000000], 0)}
          </div>
        </Col>
        <Col xs={4}>
          <div className="node-wrap">
            { renderNode(maped[9000000], 0)}
            { renderNode(maped[10000000], 0)}
            { renderNode(maped[11000000], 0)}
            { renderNode(maped[12000000], 0)}
          </div>
        </Col>
        <Col xs={4}>
          <div className="node-wrap">
            { renderNode(maped[14000000], 0)}
            { renderNode(maped[15000000], 0)}
            { renderNode(maped[99000000], 0)}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Input.TextArea rows={10} value={textarea} onChange={e => setTextarea(e.target.value) } />
          <Button onClick={onSubmit}>저장</Button>
        </Col>
      </Row>
    </div>
  );
};

export default inject("categoryStore")(observer(CategoryTree));