import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import axios from "axios";
import {Row, Col, Table, Tag, Button, TreeSelect, Tree, Input, Card, message} from "antd";
import CategoryTree from "../../organisms/CategoryTree/CategoryTree";

const CategoryList = ({ categoryStore }) => {
  const [items, setItems] = useState([]);
  const [selectedKey, setSelectedKey] = useState("");
  const [addCategoryCode, setAddCategoryCode] = useState("");
  const [addCategoryName, setAddCategoryName] = useState("");
  const [page, setPage] = useState(0);

  useEffect(() => {
    console.log("axios.defaults.headers.common['X-Auth-Token']", axios.defaults.headers.common['X-Auth-Token']);
    categoryStore.loadCategories();
  }, []);

  useEffect(() => {
    load();
  }, [selectedKey, page]);

  const onSelect = categoryCode => {
    setSelectedKey(categoryCode);
    setPage(0);
  };

  const onSubmitAddCategory = event => {
    event.preventDefault();
    categoryStore.addNewCategory(selectedKey, addCategoryName, addCategoryCode);
  };

  const onNext = () => {
    setPage(page + 1);
  };

  const load = () => {
    if(!selectedKey)
      return;

    axios.get(`/admin/categories/${selectedKey}/items?page=${page}`).then(res => {
      setItems(res.data);
    });
  };

  const onSubmitResetCache = () => {
    axios.put(`/admin/categories/cache`).then(() => {
      message.success(`초기화 성공`);
    }).catch(() => {
      message.success(`초기화 실패`);
    })
  };

  return (
    <div>
      <Row>
        <Col xs={4}>
          <div style={{marginBottom: "16px"}}>
            <Button
              onClick={onSubmitResetCache}
            >
              카테고리 캐시 초기화
            </Button>
          </div>
          <div style={{marginBottom: "16px"}}>
            <form>
              <Input.Group>
                <Input
                  style={{width: "40%" }}
                  name="code"
                  placeholder="code"
                  value={addCategoryCode}
                  onChange={e => setAddCategoryCode(e.target.value)}
                />
                <Input
                  style={{width: "40%" }}
                  name="name"
                  placeholder="name"
                  value={addCategoryName}
                  onChange={e => setAddCategoryName(e.target.value)}
                />
                <Button
                  style={{width: "20%" }}
                  onClick={onSubmitAddCategory}
                >
                  추가
                </Button>
              </Input.Group>
            </form>
          </div>
          <div
            style={{
              backgroundColor: "#ffffff",
              padding: "16px"
            }}
          >
            <CategoryTree
              onSelect={onSelect}
            />
          </div>
        </Col>
        <Col xs={20}>
          <Row>
            { items.map(item => {
              return (
                <Col
                  key={item.id}
                  xs={3}
                >
                  <Card
                    size="small"
                    cover={
                      <img alt="example" src={`${item.thumbnail}`} />
                    }
                  >
                    <Card.Meta
                      description={item.title}
                    />
                  </Card>
                </Col>
              )
            })}
            <Col xs={3}>
              <Card
                size="small"
                onClick={onNext}
              >
                <Card.Meta
                  title="다음 페이지"
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default inject("categoryStore")(observer(CategoryList));