import axios from "axios";

let interceptorId = undefined;

const run = () => {
  if(interceptorId)
    axios.interceptors.response.eject(interceptorId);

  interceptorId = axios.interceptors.response.use(
    response => {
      if (response.data.error) {
        console.error(response);
        window.alert(response.data.error);
        return Promise.reject(response.data.error);
      }

      return response
    },
    error => {
      const { response } = error;
      let errorMessage = response ? response.data.error : '전송 실패';

      if (response && response.status === 401) {
        return Promise.reject(error)
      }

      if (response && response.status === 400) {
        if (response.data) {
          if (response.data.errors) {
            const { errors } = response.data
            errorMessage = _.map(errors, err => err.defaultMessage).join('\n')
          }
        }
      }

      if (errorMessage) {
        window.alert(errorMessage)
      } else {
        const message =
          process.env.NODE_ENV === 'production'
            ? '죄송합니다. 요청에 실패하였습니다. 문제가 계속되면 고객센터로 문의해 주세요.'
            : JSON.stringify(error);

        window.alert(message)
      }

      return Promise.reject(error)
    }
  )
};

export default run;