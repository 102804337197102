// @ts-nocheck
const RouteName = {
  root: '/',
  login: '/login',
  acquireAccount: '/acquire-account',
  shopItems: '/shop/items',
  visits: "/shop/visits",
};

Object.freeze(RouteName);

export default RouteName;