import React, { useState, useEffect } from 'react';
import { DualAxes } from '@ant-design/charts';
import _ from "lodash";

const LikeDislikeConversion = ({ data }) => {
  const likeData = _.filter(data, obj => { return obj.type === "좋아요" || obj.type === "패스" });
  const conversionData = _.filter(data, obj => { return obj.type === "유료전환" || obj.type === "무료전환" });

  const config = {
    data: [likeData, conversionData],
    xField: 'date',
    yField: ['value', 'value'],
    geometryOptions: [
      {
        geometry: 'line',
        seriesField: 'type',
        color: ['#D62A0D', '#FAA219'],
        lineStyle: {
          lineWidth: 2,
        },
        smooth: true,
      },
      {
        geometry: 'line',
        seriesField: 'type',
        color: ['#1979C9', '#aaaaaa'],
        lineStyle: {
          lineWidth: 3,
        },
        smooth: true,
      },
    ],
  };
  return <DualAxes {...config} height={400} />;
};
export default LikeDislikeConversion;