import React, { useState, useEffect, useRef } from "react";
import { inject, observer } from "mobx-react";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import qs from "querystring";
import {PageHeader, Tag, Button, Statistic, Descriptions, Row, Col, Card, Table, Tabs, Modal, Form,
  Input,
  Tooltip,
  Checkbox,
  Select,
  message,
  Space,
  DatePicker
} from 'antd';
import "./ShopItems.scss";
import {CloseCircleTwoTone} from "@ant-design/icons";
import CategoryTree from "../../organisms/CategoryTree/CategoryTree";

const { RangePicker } = DatePicker;

const initialValues = { text: "", categoryCode: "", startDate: moment(), endDate: moment() }

const ItemFilter = ({ categoryStore, onSubmut, defaultStartDate, defaultEndDate, defaultText }) => {
  const [filter, setFilter] = useState(initialValues);
  const [showCategorySelectModal, setShowCategorySelectModal] = useState(false);

  useEffect(() => {
    if(defaultStartDate) {
      const startDate = moment(defaultStartDate).startOf("day")
      const endDate = moment(defaultEndDate).endOf("day")
      const next = { ...filter, startDate, endDate }

      setFilter(next);
      onSubmut(next);
    }
    if(defaultText) {
      const next = { ...filter, text: defaultText, startDate: undefined, endDate: undefined }
      setFilter(next);
      onSubmut(next);
    }
  }, [])

  const onFilterDateChanged = dates => {
    const start = dates[0];
    const end = dates[1];
    const startDate = start.startOf("day");
    const endDate = end.endOf("day");

    setFilter({ ...filter, startDate, endDate });
  }

  const setFilterDateRange = period => {
    let startDate = undefined;
    let endDate = undefined;

    if(period === "오늘") {
      startDate = moment();
      endDate = moment();
    }
    else if(period === "어제") {
      startDate = moment().subtract(1, 'day');
      endDate = moment().subtract(1, 'day');
    }
    else if(period === "최근 일주일") {
      startDate = moment().subtract(6, 'day');
      endDate = moment();

    }
    else if(period === "최근 1달") {
      startDate = moment().subtract(1, 'month');
      endDate = moment();
    }
    else if(period === "이번 달") {
      startDate = moment().startOf("month");
      endDate = moment().endOf("month");
    }
    else if(period === "저번 달") {
      startDate = moment().subtract(1, "month").startOf("month");
      endDate = moment().subtract(1, "month").endOf("month");
    }

    setFilter({ ...filter, startDate, endDate });
  }

  const reset = () => {
    setFilter({ ...initialValues });
  }

  return (
    <>
      <Row gutter={[20, 10]}>
        <Col xs={2}>
          검색어
        </Col>
        <Col xs={4}>
          <Input
            placeholder="상품명, 상품코드"
            allowClear
            value={filter.text}
            onChange={event => setFilter({ ...filter, text: event.target.value})}
          />
        </Col>
      </Row>
      <Row gutter={[20, 10]}>
        <Col xs={2}>
          카테고리
        </Col>
        <Col xs={6}>
          <Space>
            { !filter.categoryCode && (
              <Button onClick={() => setShowCategorySelectModal(true)}>
                선택
              </Button>
            )}
            { filter.categoryCode && (
              <>
                <div
                  style={{
                    padding: "4px 11px",
                    color: "rgba(0, 0, 0, 0.85)",
                    fontSize: "14px",
                    lineHeight: "1.5715",
                  }}
                >
                  {categoryStore.getFullName(filter.categoryCode)}
                </div>
                <span onClick={() => setFilter({ ...filter, categoryCode: "" })} style={{ cursor: "pointer" }}>
                    <CloseCircleTwoTone twoToneColor="#aaa" />
                  </span>
              </>
            ) }
          </Space>
        </Col>
      </Row>
      <Row gutter={[20, 10]}>
        <Col xs={2}>
          등록일
        </Col>
        <Col xs={22}>
          <Space>
            <RangePicker
              onChange={onFilterDateChanged}
              value={[filter.startDate, filter.endDate]}
            />
            { ["오늘", "어제", "최근 일주일", "최근 1달", "이번 달", "저번 달"].map(period => (
              <Button
                key={period}
                onClick={() => setFilterDateRange(period)}
              >
                {period}
              </Button>
            ))}
          </Space>
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col xs={2}>
          정렬방법
        </Col>
        <Col xs={6}>
          <Select defaultValue="createDate-DESC" style={{ width: 120 }} onChange={undefined}>
            <Select.Option value="createDate-ASC">등록일순</Select.Option>
            <Select.Option value="createDate-DESC">등록일역순</Select.Option>
            <Select.Option value="ranking-ASC">인기순</Select.Option>
            <Select.Option value="ranking-DESC">인기역순</Select.Option>
          </Select>
        </Col>
      </Row>
      <Row gutter={[20, 60]}>
        <Col offset={2}>
          <Space>
            <Button
              type="primary"
              onClick={() => onSubmut(filter)}
            >
              검색
            </Button>
            <Button type="text" onClick={reset}>초기화</Button>
          </Space>
        </Col>
      </Row>
      <CategorySelectModal
        visible={showCategorySelectModal}
        setVisible={setShowCategorySelectModal}
        onOk={categoryCode => { setFilter( {...filter, categoryCode }) }}
      />
    </>
  )
};


const CategorySelectModal = ({ visible, setVisible, onOk }) => {
  const [selectedCategoryCode, setSelectedCategoryCode] = useState(undefined);

  const onOkInner = () => {
    onOk(selectedCategoryCode);
    setVisible(false);
  };

  return (
    <Modal
      title="카테고리 선택"
      visible={visible}
      onOk={onOkInner}
      onCancel={() => setVisible(false)}
      cancelText="닫기"
      width="320px"
    >
      <CategoryTree
        onSelect={key => setSelectedCategoryCode(key)}
      />
    </Modal>
  )
};

export default inject("categoryStore", "siteStore")(observer(ItemFilter));