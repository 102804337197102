import React, { useState, useEffect, useRef } from "react";
import { inject, observer } from "mobx-react";
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import _ from "lodash";
import {PageHeader, Tag, Button, Statistic, Descriptions, Row, Col, Card, Table, Tabs, Modal, Form,
  Input,
  Tooltip,
  Checkbox,
  Select,
  message
} from 'antd';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import CategoryTree from "../../organisms/CategoryTree/CategoryTree";
import {getParentCode} from "../../../store/CategoryStore";
import {SearchOutlined} from "@ant-design/icons";

window._ = _;

const { Column } = Table;
const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 5,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 19,
    },
  },
};

const statuses = ['DISPLAY', 'QUEUED','CRAWLING','ERROR','DELETED', 'HIDE'];

const SiteDetail = ({ categoryStore }) => {
  const { siteId } = useParams();
  const [site, setSite] = useState({});
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(undefined);
  const [items, setItems] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(statuses[0]);
  const [selectedItem, setSelectedItem] = useState({});
  const [showItemModal, setShowItemModal] = useState(false);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [showEditImage, setShowEditImage] = useState(false);
  const [editingImage, setEditingImage] = useState(undefined);
  const [countsByStatus, setCountsByStatus] = useState({});
  const searchInput = useRef();
  const [searchText, setSearchText] = useState();
  const [searchedColumn, setSearchedColumn] = useState();


  useEffect(() => {
    categoryStore.loadCategories();

    axios.get(`/admin/sites/${siteId}`).then(res => {
      const { site, categories } = res.data;
      setSite(site);

      categories.forEach(siteCategory => {
        const { categoryCode } = siteCategory;
        const parentCode = getParentCode(categoryCode);

        const depth1 = categoryStore.categories[parentCode];
        const depth2 = categoryStore.categories[categoryCode];

        siteCategory["depth1"] = (depth1 && depth1.name) || "?";
        siteCategory["depth2"] = (depth2 && depth2.name) || "-";
      });

      console.log(categoryStore.categories);
      setCategories(categories);
    });
  }, []);

  useEffect(() => {
    const status = selectedStatus;
    const selectedStatusItems = _.filter(allItems, { status });
    setItems(selectedStatusItems);
  }, [allItems, selectedStatus]);

  useEffect(() => {
    const countsByStatus = {};
    statuses.forEach(status => {
      countsByStatus[status] = _.countBy(allItems, { status })['true'];
    });
    console.log(countsByStatus);

    setCountsByStatus(countsByStatus);
  }, [allItems]);

  const selectCategory = (categoryId) => {
    setSelectedCategory(categoryId);
    fetchItems(categoryId);
  };

  const fetchItems = (categoryId) => {
    axios.get(`/admin/sites/${site.id}/categories/${categoryId}/items`).then(res => {
      const items = res.data;
      setAllItems(items);
    });
  };

  const changeTab = key => {
    setSelectedStatus(key);
  };

  const editImage = image => {
    setShowEditImage(true);
    setEditingImage(image)
  };

  const crawling = () => {
    const yes = confirm("크롤링 시작?");
    if(yes) {
      message.info(`${site.name} 크롤링 시작`);

      axios.post(`/admin/sites/${site.id}/crawling`).then(res => {
        message.success(`${site.name} 크롤링 완료`);
      })
    }
  };

  const crawlingCategory = categoryId => {
    const yes = confirm("크롤링 시작?");
    if(!yes)
      return;

    message.info(`${site.name} 크롤링 시작`);

    axios.post(`/admin/categories/${categoryId}/crawling`).then(res => {
      message.success(`${site.name} 크롤링 완료`);
    })
  }

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <div>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      // if (visible) {
      //   setTimeout(() => searchInput.select());
      // }
    }
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
    };

  if (!site)
    return null;

  return (
    <div>
      <PageHeader
        title={site.name}
        tags={<Tag color="blue">{site.status}</Tag>}
        subTitle={<a href={site.domain} target="_blank" rel="noopener noreferrer">{site.domain}</a>}
        style={{background: "#fff"}}
        extra={[
          <Button key="3">Operation</Button>,
          <Button onClick={crawling}>크롤링</Button>,
          <Button key="1" type="primary">
            reload
          </Button>,
        ]}
      >
        <Row>
          <Statistic title="카테고리" value={2} />
          <Statistic
            title="상품"
            value={568}
            style={{
              margin: '0 32px',
            }}
          />
        </Row>
      </PageHeader>
      <Row gutter={8}>
        <Col xs={8}>
          <Card>
            <Button
              onClick={() => setShowAddCategoryModal(true)}
            >
              추가
            </Button>
            <Table
              dataSource={categories}
              size="small"
              rowKey="id"
            >
              <Column title="id" dataIndex="id" key="id" />
              <Column title="대분류" dataIndex="depth1" key="depth1" />
              <Column
                title="중분류"
                dataIndex="depth2"
                key="depth2"
                render={(value, category) => (
                  <>
                    <a href={`${site.domain}${category.url}`} target="_blank">
                      {value}
                    </a>
                    {JSON.stringify()}
                  </>
                )}
              />
              <Column
                title=""
                dataIndex="categoryId"
                key="categoryId"
                render={value => (
                  <>
                    <Button size="small" onClick={() => selectCategory(value)}>
                      보기
                    </Button>
                    <Button size="small" onClick={() => crawlingCategory(value)}>
                      크롤링
                    </Button>
                  </>
                )}
              />
            </Table>
          </Card>
        </Col>
        <Col xs={16}>
          <Card>
            카테고리 / URL
            <Tabs onChange={changeTab} type="card">
              { statuses.map(status => {
                const count = countsByStatus[status];
                const name = status + (count ? ` (${count})` : "");
                return (
                  <TabPane
                    key={status}
                    tab={name}
                  />
                )
              })}
            </Tabs>
            <Table
              dataSource={items}
              size="small"
              rowKey="id"
              pagination={{
                pageSize: 10
              }}
            >
              <Column
                title="아이템코드"
                dataIndex="itemCode"
                key="itemCode"
                render={(_, row) => {
                  const { id, itemCode } = row;
                  return (
                    <div>
                      <div>{itemCode}</div>
                      <div><small>#{id}</small></div>
                    </div>
                  )
                }}
              />
              <Column title="상태" dataIndex="status" key="status" />
              <Column
                title="상품"
                dataIndex="id"
                key="id"
                {...getColumnSearchProps("title")}
                render={(_, row) => {
                  const { title, price, discountPrice } = row;
                  if(discountPrice) {
                    return (
                      <div>
                        <div>{title}</div>
                        <div>
                          <span>
                            <span className="strike">{price}</span>
                            {" → "}
                            <span>{discountPrice}</span>
                          </span>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div>
                        <div>{title}</div>
                        <div>
                          <span>
                            <span>{price}</span>
                          </span>
                        </div>
                      </div>
                    );
                  }
                }}
              />
              <Column
                title=""
                dataIndex="id"
                key="id"
                render={(id, item) => (
                  <>
                    <Button size="small" onClick={() => {
                      setSelectedItem(item);
                      setShowItemModal(true);
                    }}>
                      자세히
                    </Button>
                    <a href={`${site.domain}${item.url}`} target="_blank">
                      <Button size="small">
                        원본
                      </Button>
                    </a>
                  </>
                )}
              />
            </Table>
          </Card>
        </Col>
      </Row>
      <AddCategoryForm
        site={site}
        visible={showAddCategoryModal}
        onCancel={() => setShowAddCategoryModal(false)}
        onOk={() => setShowAddCategoryModal(false)}
      />
      <Modal
        title={selectedItem.title}
        visible={showItemModal}
        onOk={() => setShowItemModal(false)}
        onCancel={() => setShowItemModal(false)}
        cancelText="닫기"
        width="1400px"
      >
        <ItemDetailForm
          site={site}
          itemId={selectedItem.id}
          editImage={editImage}
        />
      </Modal>
      <Modal
        title={selectedItem.title}
        visible={showEditImage}
        onOk={() => setShowEditImage(false)}
        onCancel={() => setShowEditImage(false)}
        cancelText="닫기"
        width="1400px"
      >
        <EditImageModal
          site={site}
          image={editingImage}
        />
      </Modal>
    </div>
  )
};

const AddCategoryForm = ({ site, visible, onOk, onCancel }) => {
  const formRef = useRef();
  const [form] = Form.useForm();
  const [selectedCategoryCode, setSelectedCategoryCode] = useState(undefined);

  const onOkInner = () => {
    form.submit();
  };

  const onSubmit = () => {
    const params = { ...form.getFieldsValue() };
    let url = params.url

    if(_.startsWith(url, "http")) {
      const withoutScheme = url.substring(url.indexOf("://") + "://".length, url.length);
      const path = withoutScheme.substring(withoutScheme.indexOf("/"), withoutScheme.length)

      params.url = path
      form.setFieldsValue({ url: path })
    }

    params.categoryId = selectedCategoryCode;

    axios.post(`/admin/sites/${site.id}/categories`, params).then(res => {
      if(onOk) onOk();
      setSelectedCategoryCode(undefined)
      form.resetFields(Object.keys(params));
    })
  };

  const onPaste = e => {
    e.stopPropagation();
    e.preventDefault();

    const data = e.clipboardData.getData('Text')
    const values = _.split(data, '\t');

    if(values.length >= 2) {
      const categoryNameInShop = values[0];
      const url = values[1];

      form.setFieldsValue({
        url: url,
        siteCategoryName: categoryNameInShop
      })
    }
    if(values.length >= 3) {
      const categoryCodeInShop = values[2]

      form.setFieldsValue({
        siteCategoryCode: categoryCodeInShop
      })
    }
  }

  return (
    <Modal
      title="카테고리 추가"
      visible={visible}
      onOk={onOkInner}
      onCancel={onCancel}
      cancelText="닫기"
      width="600px"
    >
      <Form
        labelCol={{
          sm: {
            span: 8,
          }
        }}
        wrapperCol={{
          sm: {
            span: 16,
          }
        }}
        form={form}
        name="add-category"
        ref={formRef}
        onFinish={onSubmit}
        scrollToFirstError
      >
        <Form.Item
          name="categoryId"
          label="카테고리"
        >
          <div style={{ background: "#dadada" }}>
            <CategoryTree
              onSelect={key => setSelectedCategoryCode(key)}
            />
          </div>
        </Form.Item>
        <Form.Item
          name="siteCategoryName"
          label="사이트 카테고리명"
        >
          <Input placeholder="반팔티" onPaste={onPaste} />
        </Form.Item>
        <Form.Item
          name="url"
          label="URL"
        >
          <Input placeholder="/shop/shopbrand.html?xcode=001&type=M&mcode=001" onPaste={onPaste} />
        </Form.Item>
        <Form.Item
          name="siteCategoryCode"
          label="사이트 카테고리코드"
        >
          <Input placeholder="12" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const ItemDetailForm = ({ itemId, editImage, site }) => {
  const formRef = useRef();
  const [item, setItem] = useState({});
  const [grouped, setGrouped] = useState([]);
  const [form] = Form.useForm();
  const [notIgnoredImages, setNotIgnoredImages] = useState([]);


  useEffect(() => {
    form.setFieldsValue({});
    setItem({ images: [] });

    axios.get(`/admin/items/${itemId}`).then(res => {
      const item = res.data;
      setItem(item);
      form.setFieldsValue(item);


      const originals = _.filter(item.images, image => { return image.original && (image.status !== "ERROR")});
      const grouped = [];
      originals.forEach(parent => {
        const children = _.filter(item.images, child => {
          return !child.original && child.originalUrl === parent.originalUrl
        });

        grouped.push({
          parent,
          children
        })
      });

      setGrouped(grouped);
    })
  }, [itemId]);

  useEffect(() => {
    refreshNotIgnoredImages();
  }, [item]);

  const hide = imageId => {
    const url = `/admin/items/${itemId}/images/${imageId}/hide`;
    axios.patch(url).then(() => {
      findImageAndChangeStatus(imageId, "HIDE");
      refreshNotIgnoredImages();

    }).catch(error => {
      const msg = error.response && error.response.data || error.message;
      message.error("ERROR! " + msg);
    });
  };

  const display = imageId => {
    const url = `/admin/items/${itemId}/images/${imageId}/display`;
    axios.patch(url).then(() => {
      findImageAndChangeStatus(imageId, "DISPLAY");
      refreshNotIgnoredImages();
    }).catch(error => {
      const msg = error.response && error.response.data || error.message;
      message.error("ERROR! " + msg);
    });
  };

  const findImageAndChangeStatus = (imageId, status) => {
    const filtered = _.filter(notIgnoredImages, image => {
      return image.itemImageId === imageId
    });

    filtered.forEach(image => {
      image.status = status;
    });
  };

  const addExcludeFilename = image => {
    if(!window.confirm("사이트 제외에 추가하시겠습니까?"))
      return;

    const url = image.originalUrl;
    const filename = url.substring(url.lastIndexOf("/") + 1);

    axios.post(`/admin/sites/${site.id}/excludes-file?filename=${filename}`).then(() => {
      message.success("사이트 제외 파일명에 추가하였습니다.");
    }).catch(error => {
      const msg = error.response && error.response.data || error.message;
      message.error("ERROR! " + msg);
    });
  };

  const onFinish = values => {
    console.log('Received values of form: ', values);
  };

  const crawlingItem = () => {
    message.info(`${site.name} 크롤링 시작`);
    axios.post(`/admin/items/${item.id}/crawling`).then(res => {
      message.success(`크롤링 완료`);
    }).catch(() => {
      message.error("크롤링 실패");
    })
  };

  const refreshNotIgnoredImages = () => {
    const notIgnoredImages = _.filter(item.images, image => { return image.status === "DISPLAY" || image.status === "HIDE" });
    setNotIgnoredImages(notIgnoredImages);
  };


  return (
    <Row gutter={16}>
      <Col xs={10}>
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          ref={formRef}
          onFinish={onFinish}
          scrollToFirstError
        >
          <Row gutter={8}>
            <Col xs={8}>
              <Form.Item
                name="id"
                label="#"
                labelCol={{
                  xs: {
                    span: 24,
                  },
                  sm: {
                    span: 15,
                  },
                }}
                wrapperCol={{
                  xs: {
                    span: 24,
                  },
                  sm: {
                    span: 9,
                  },
                }}
              >
                <Input style={{ width: 100 }} />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item
                label="#"
                labelCol={{
                  xs: {
                    span: 24,
                  },
                  sm: {
                    span: 10,
                  },
                }}
                wrapperCol={{
                  xs: {
                    span: 24,
                  },
                  sm: {
                    span: 14,
                  },
                }}
              >
                {}
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item
                name="status"
                label="status"
              >
                <Select style={{ width: "100%" }} >
                  <Option value="QUEUED" style={{backgroundColor: "#7777FF"}}>QUEUED</Option>
                  <Option value="CRAWLING" style={{backgroundColor: "#77FF77"}}>CRAWLING</Option>
                  <Option value="NORMAL">NORMAL</Option>
                  <Option value="ERROR" style={{backgroundColor: "#FF7777"}}>ERROR</Option>
                  <Option value="DELETED" style={{backgroundColor: "#FF7777"}}>DELETED</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="title"
            label="상품명"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="price"
            label="가격"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="discountPrice"
            label="할인가격"
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="description"
            label="상품설명"
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item
            name="url"
            label="URL"
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="thumbnail"
            label="thumbnail"
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="imageHash"
            label="이미지 해시"
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="재고"
            label="hasStock"
          >
            <Checkbox>있음</Checkbox>
          </Form.Item>
        </Form>
        <div>
          {notIgnoredImages.map(image => {
            const url = `${encodeURIComponent(image.url)}`;
            const displaying = image.status === "DISPLAY";
            const background = displaying ? "#EEE" : "#C99";
            const opacity = displaying ? "1" : "0.9";
            const onClick = displaying ? hide : display;
            return (
              <div
                onClick={() => onClick(image.itemImageId)}
                style={{
                  width: "120px",
                  height: "120px",
                  background: background,
                  opacity: opacity,
                  textAlign: "center",
                  padding: "8px",
                  margin: "0 4px 4px 0",
                  float: "left",
                  pointer: "cursor"
                }}
              >
                <img src={url} style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                }} />
              </div>
            )
          })}
        </div>
      </Col>
      <Col xs={14}>
        <div>
          <Button
            size="small"
            onClick={crawlingItem}
          >
            크롤링
          </Button>
        </div>
        {grouped.map(group => {
          const image = group.parent;
          const url = image.url
            ? `${image.url}`
            : image.originalUrl;
          const filename = url.substring(url.lastIndexOf("/") + 1);

          return (
            <div
              key={image.itemImageId}
              style={{marginBottom: "32px", padding: "16px", backgroundColor: "#555555"}}
            >
              <Row gutter={16}>
                <Col span={8}>
                  <div>
                    <img src={url} style={{maxWidth: "100%"}} />
                  </div>
                </Col>
                <Col span={16} style={{color: "#ffffff"}}>
                  <Row>
                    <Col>
                      <div>
                        <div>STATUS: {image.status}</div>
                        <div>ERROR: {image.error}</div>
                        <div>FILENAME: {filename}</div>
                        <div>
                          <a href={image.originalUrl} target="_blank" rel="noopener noreferrer">
                            원본
                          </a>
                        </div>
                      </div>
                      <div>
                        <Button
                          size="small"
                          onClick={() => editImage(image)}
                        >
                          편집
                        </Button>
                      </div>
                      <div style={{marginTop: "16px"}}>
                        <Button
                          size="small"
                          onClick={() => hide(image.itemImageId)}
                        >
                          숨김
                        </Button>
                        <Button
                          size="small"
                          onClick={() => addExcludeFilename(image)}
                        >
                          사이트 전체 제외
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  { group.children.map(image => {
                    const url = image.url
                      ? `${image.url}`
                      : image.originalUrl;

                    const filename = url.substring(url.lastIndexOf("/") + 1);
                    return (
                      <div
                        style={{marginTop: "32px", padding: "16px", backgroundColor: "#333333"}}
                      >
                        <Row gutter={16}>
                          <Col xs={12}>
                            <div>
                              <img src={url} style={{maxWidth: "100%"}} />
                            </div>
                          </Col>
                          <Col xs={12}>
                            <div>
                              <div>STATUS: {image.status}</div>
                              <div>ERROR: {image.error}</div>
                              <div>FILENAME: {filename}</div>
                            </div>
                            <div>
                              <Button
                                size="small"
                                onClick={() => editImage(image)}
                              >
                                편집
                              </Button>
                            </div>
                            <div style={{marginTop: "16px"}}>
                              <Button
                                size="small"
                                onClick={() => hide(image.itemImageId)}
                              >
                                숨김
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    )
                  })}
                </Col>
              </Row>
            </div>
          )
        })}
      </Col>
    </Row>
  )
};

const EditImageModal = ({ site, image }) => {

  useEffect(() => {
    const editImageElement = document.getElementById("edit-image");
    console.log("editImageElement", editImageElement);
    const cropper = new Cropper(editImageElement, {
      viewMode: 1,
      aspectRatio: 1,
      crop(event) {
      },
    });
    console.log("cropper", cropper);
  }, [image]);

  if (!image)
    return null;

  return (
    <div>
      <img id="edit-image" src={`${image.url}`} alt=""/>
    </div>
  )

}

export default inject("categoryStore")(observer(SiteDetail));