import React, {useState} from "react";
import {inject, observer} from "mobx-react";
import {Tree} from "antd";

const CategoryTree = ({ categoryStore, onSelect }) => {
  const [selectedKey, setSelectedKey] = useState(undefined);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const onSelectInner = selectedIds => {
    const selectedId = selectedIds[0];
    setSelectedKey(selectedId);

    if (onSelect) {
      onSelect(selectedId);
    }
  };

  const onExpandInner = expandedKeys => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  if(!categoryStore.categoryTree)
    return null;

  return (
    <Tree
      treeData={categoryStore.categoryTree}
      onExpand={onExpandInner}
      onSelect={onSelectInner}
      expandedKeys={expandedKeys}
      autoExpandParent={autoExpandParent}
      selectedKeys={[selectedKey]}
    />
  )
};

export default inject("categoryStore")(observer(CategoryTree));