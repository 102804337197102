import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Table, Tag, Button } from "antd";
import "./SiteList.scss";

const { Column } = Table;

const SiteList = ({}) => {
  const [selectedRowKeys, selectRowKey] = useState([]);
  const [sites, setSites] = useState([]);

  useEffect(() => {
    console.log("get /admin/sites", axios.defaults.headers.common)
    axios.get("/admin/sites").then(res => {
      const sites = res.data;
      setSites(sites);
    });
  }, []);
  const onSelectChange = () => {

  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div>
      <Table rowSelection={rowSelection} dataSource={sites} rowKey="id">
        <Column title="id" dataIndex="id" key="id" />
        <Column title="사이트명" dataIndex="name" key="name" />
        <Column
          title="상태"
          dataIndex="status"
          key="status"
          render={value => (
            <Tag color="blue" key={value}>
              {value}
            </Tag>
          )}
        />
        <Column
          title="도메인"
          dataIndex="domain"
          key="domain"
          render={value => (
            <a href={value} target="_blank" rel="noopener noreferrer">
              {value}
            </a>
          )}
        />
        <Column
          title="솔루션"
          dataIndex="solutionType"
          key="solutionType"
        />
        <Column
          title=""
          dataIndex="id"
          key="id"
          render={value => (
            <>
              <Link to={`/sites/${value}`}>
                <Button size="small">
                  이동
                </Button>
              </Link>
            </>
          )}
        />
      </Table>
    </div>
  )
};

export default SiteList;