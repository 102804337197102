import React from "react";
import { useHistory } from "react-router-dom";
import {Row, Col, Form, Input, Button, Checkbox, Card, Divider, Typography} from 'antd';
import {inject, observer} from "mobx-react";

const { Text } = Typography;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 10 },
};

const Login = ({ loginStore }) => {
  const history = useHistory();

  const onFinish = params => {
    const { userId, userPw, remember } = params;
    loginStore.login(userId, userPw, remember).then(() => {
      const isRoot = loginStore.isRoot()
      if(isRoot)
        history.replace("/sites")
      else
        history.replace("/shop")
    }).catch(err => {
      console.log("err", err);
      console.log("err.response", err.response);
      const message = err.response && err.response.data.message;
      if(message)
        alert(message);
    })
  };

  return (
    <div style={{ height: "100vh", backgroundColor: "rgba(255,127,104,0.22)", paddingTop: "200px" }}>
      <Row>
        <Col span={10} offset={7}>
          <Card>
            <div style={{ textAlign: "center", padding: "24px" }}>
              <img src="/images/icon_bi_text.png" style={{ width: "160px", margin: "0 auto"}} />
            </div>
            <Form
              {...layout}
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={() => console.log("onFinishFailed")}
            >
              <Form.Item
                label="ID"
                name="userId"
                rules={[{ required: true, message: 'ID를 입력해주세요.' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Password"
                name="userPw"
                rules={[{ required: true, message: '비밀번호를 입력해주세요.' }]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                <Checkbox>자동로그인</Checkbox>
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit" block >
                  로그인
                </Button>
              </Form.Item>
            </Form>
            <Divider />
            <div style={{textAlign: "center"}}>
              <Text type="secondary">
                문의 사항이 있으시다면 카카오톡을 통해 문의 바랍니다.
              </Text>
              <div>
                <a href="http://pf.kakao.com/_pmmqK/chat" target="_blank" rel="noopener noreferrer"
                   style={{marginTop: "8px", display: "inline-block", padding: "5px 16px", borderRadius: "3px", background: "#fae100", color: "#3b2021", fontWeight: "bold", fontSize: "12px"}}>
                  카카오톡 문의하기
                </a>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>

  )
};

export default inject("loginStore")(observer(Login));