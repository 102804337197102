import React, { useEffect } from "react";
import {BrowserRouter as Router, Switch, Route, Link, Redirect} from "react-router-dom";
import { hot } from 'react-hot-loader/root';
import axios from "axios";
import {Layout, Menu, Button, message, } from 'antd';
import {LineChartOutlined, PoweroffOutlined} from '@ant-design/icons';
import loginStore from "./store/LoginStore";
import SiteList from "./component/pages/SiteList/SiteList";
import SiteDetail from "./component/pages/SiteDetail/SiteDetail";
import CategoryList from "./component/pages/CategoryList/CategoryList";
import {observer} from "mobx-react";
import AuthDaemon from "./component/daemon/AuthDaemon";
import Login from "./component/pages/Login/Login";
import axiosAddInterceptor from "./util/AxiosAddInterceptor";
import CategoryTree from "./component/pages/CategoryTree/CategoryTree";
import NewStore from "./component/pages/NewStore/NewStore";
import Dashboard from "./component/pages/Dashboard/Dashboard";
import ShopDashboard from "./component/pages/ShopDashboard/ShopDashboard";
import ShopItems from "./component/pages/ShopItems/ShopItems";
import ShopCategory from "./component/pages/ShopCategory/ShopCategory";
import ShopSetting from "./component/pages/ShopSetting/ShopSetting";
import ShopSupport from "./component/pages/ShopSupport/ShopSupport";
import LNB from "./component/organisms/LNB/LNB";
import ShopAccounts from "./component/pages/ShopAccounts/ShopAccounts";
import SignUp from "./component/pages/SignUp/SignUp";
import AcquireAccount from "./component/pages/AcquireAccount/AcquireAccount";
import RouteName from "./RouteNames";
import ShopVisit from "./component/pages/ShopVisit/ShopVisit";

const {Header, Content, Sider} = Layout;

class App extends React.Component {


  constructor(props) {
    super(props);

    axios.defaults.baseURL = 'https://api.cherrypk.io';
    if(process.env.NODE_ENV !== "production")
      axios.defaults.baseURL = 'http://localhost:8080';

    const token = localStorage.getItem("X-Auth-Token");
    if(!!token) {
      axios.defaults.headers.common['X-Auth-Token'] = token;
    } else {
      loginStore.session = null;
    }

    axiosAddInterceptor()
  }

  logout = () => {
    loginStore.logout().then(() => {
      message.info("로그아웃 되었습니다.");
      window.location.href = RouteName.login;

    });
  }

  render() {
    const token = localStorage.getItem("X-Auth-Token");
    if(!loginStore.session && token) {
      return (
        <Router>
          <Route path="">
            <AuthDaemon />
          </Route>
        </Router>
      )
    }

    if(!loginStore.session) {
      return (
        <Router>
          <Route path="">
            <AuthDaemon />
          </Route>
          <Route path={RouteName.login}>
            <Layout>
              <Login/>
            </Layout>
          </Route>
          <Route path="/sign-up">
            <Layout>
              <SignUp/>
            </Layout>
          </Route>
          <Route path={RouteName.acquireAccount}>
            <Layout>
              <AcquireAccount/>
            </Layout>
          </Route>
        </Router>
      )
    }

    return (
      <Router>
        <Switch>
          <Route path="/login">
            <Layout>
              <Login/>
            </Layout>
          </Route>
          <Route path="/sign-up">
            <Layout>
              <SignUp/>
            </Layout>
          </Route>
          <Route>
            <Layout>
              <Header
                className="header"
                style={{height: "46px", lineHeight: "46px", backgroundColor: "#1890ff", borderBottom: "1px solid #efefef"}}
              >
                <div style={{ textAlign: "right" }}>
                  <Button
                    ghost
                    icon={<PoweroffOutlined />}
                    onClick={this.logout}
                  >
                    로그아웃
                  </Button>
                </div>
              </Header>
              <Layout>
                <Sider width={200} className="site-layout-background">
                  <LNB />
                </Sider>
                <Layout style={{padding: '24px'}}>
                  <Content>
                    <Switch>
                      <Route exact path="/dashboard">
                        <Dashboard />
                      </Route>
                      <Route exact path="/sites">
                        <SiteList />
                      </Route>
                      <Route exact path="/sites/:siteId">
                        <SiteDetail />
                      </Route>
                      <Route exact path="/categories">
                        <CategoryList />
                      </Route>
                      <Route exact path="/categoryTree">
                        <CategoryTree />
                      </Route>
                      <Route exact path="/new-store">
                        <NewStore />
                      </Route>
                      <Route exact path="/shop">
                        <ShopDashboard />
                      </Route>
                      <Route exact path={RouteName.shopItems}>
                        <ShopItems />
                      </Route>
                      <Route exact path="/shop/categories">
                        <ShopCategory />
                      </Route>
                      <Route exact path="/shop/visits">
                        <ShopVisit />
                      </Route>
                      <Route exact path="/shop/setting">
                        <ShopSetting />
                      </Route>
                      <Route exact path="/shop/support">
                        <ShopSupport />
                      </Route>
                      <Route exact path="/accounts">
                        <ShopAccounts />
                      </Route>
                    </Switch>
                  </Content>
                </Layout>
              </Layout>
            </Layout>
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default hot(observer(App));