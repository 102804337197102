import { observable, action } from "mobx";
import axios from "axios";
import siteStore from "./SiteStore";

class LoginStore {
  @observable session = undefined;
  @observable shop = undefined;
  @observable shops = [];
  @observable authorities = [];

  @action
  ping = () => {
    return axios.get("/ping").then(res => {
      const { id, username, token, authorities, shops } = res.data;

      this.authorities = authorities;
      this.shops = shops;
      this.shop = shops[0];

      siteStore.handleOwnShops(shops);

      this.session = { id, username, token };

      return res;
    });
  };

  @action
  login = (loginId, loginPw, remember) => {
    const params = { loginId, loginPw, remember };
    return axios.post("/sign-in", params).then(res => {
      const { id, username, token, authorities, shops } = res.data;

      this.authorities = authorities;
      this.shops = shops;
      this.shop = shops[0];

      siteStore.handleOwnShops(shops);

      axios.defaults.headers.common['X-Auth-Token'] = token;
      this.session = { id, username, token };

      localStorage.setItem("X-Auth-Token", token);
      return res;
    })
  }

  @action
  logout = () => {
    return axios.delete("/sign-out").then(res => {
      this.deleteToken()
      return res;
    })
  }

  @action
  deleteToken = () => {
    localStorage.removeItem("X-Auth-Token")
    this.session = undefined;

    delete axios.defaults.headers.common['X-Auth-Token']
  }

  isRoot = () => {
    return !!_.find(this.authorities, it => it === "ROOT")
  }

}

export default new LoginStore();