import React, { useState, useEffect } from 'react';
import {Column} from "@ant-design/charts";
import {Card} from "antd";

const UserCount = ({ data }) => {

  const config = {
    data,
    xField: 'date',
    yField: 'value',
    color: ['#ae331b', '#1a6179'],
    seriesField: 'type',
    isStack: true,
  };

  return (
    <Column {...config} height={300} />
  )
}

export default UserCount;