import React, { useState, useEffect, useRef } from "react";
import { inject, observer } from "mobx-react";
import {useParams} from "react-router-dom";
import axios from "axios";
import _ from "lodash";
import qs from "querystring";
import {
  PageHeader,
  Tag,
  Button,
  Statistic,
  Descriptions,
  Row,
  Col,
  Card,
  Table,
  Tabs,
  Modal,
  Form,
  Input,
  Tooltip,
  Checkbox,
  Select,
  message,
  Space, Divider, Typography
} from 'antd';
import {QuestionCircleFilled} from "@ant-design/icons";
import moment from "moment";

const { Text } = Typography;
const { Option } = Select;
const { Column } = Table;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const ShopAccounts = ({ siteStore }) => {
  const [accounts, setAccounts] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);

  useEffect(() => {
    loadAccounts();
  }, [])

  const loadAccounts = () => {
    // axios.get(`/partner/notices`).then(res => {
    //   const { data } = res;
    //   setNotices(data);
    // });
    setAccounts([
      {
        id: 1,
        email: "leesh312somelong@gmail.com",
        status: "ACTIVATED",
        loginId: "somelongidentity312",
        type: "MANAGER",
        name: "이승환",
        createDate: "2020-09-05 00:00:00"
      },
      {
        id: 2,
        email: "leesh@cherries.kr",
        status: "PENDING",
        loginId: "leesh",
        type: "SUB_MANAGER",
        name: "귀여운멩",
        createDate: "2020-09-05 00:00:00"
      }
    ])
  }

  const onSubmitAddAccount = form => {
    const params = { ...form.getFieldsValue() };
    console.log("params: ", params)
  }

  return (
    <div>
      <Row gutter={20}>
        <Col xs={{ offset: 5, span: 14 }}>
          <Card>
            <PageHeader
              title="계정 관리"
              className="site-page-header"
              subTitle="매니저 권한을 부여/해제 할 수 있습니다."
              tags={<Tag color="blue">소유자 전용</Tag>}
              style={{ marginBottom: "40px" }}
            />
            <Row gutter={[20, 20]}>
              <Col xs={24}>
                <div style={{ textAlign: "right", marginBottom: "10px" }}>
                  <Button type="primary" onClick={() => setShowAddModal(true)}>계정 추가</Button>
                </div>
                <Table
                  dataSource={accounts}
                >
                  <Column title="#" dataIndex="id" key="id" width={50} />
                  <Column
                    title="구분"
                    dataIndex="type"
                    key="type"
                    render={value => (tagByType(value))}
                  />
                  <Column title="email" dataIndex="email" key="email" />
                  <Column
                    title="ID"
                    dataIndex="loginId"
                    key="loginId"
                    width={210}
                  />
                  <Column
                    title="이름"
                    dataIndex="name"
                    key="name"
                    width={90}
                  />
                  <Column
                    title="상태"
                    dataIndex="status"
                    key="status"
                    width={100}
                    render={value => (tagByStatus(value))}
                  />
                  <Column
                    title="등록일"
                    dataIndex="createDate"
                    key="createDate"
                    render={date => (moment(date).format("yyyy-MM-DD"))}
                    width={120}
                  />
                </Table>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <AddAccountModal
        visible={showAddModal}
        setVisible={setShowAddModal}
        onOk={onSubmitAddAccount}
      />
    </div>
  )
};

const tagByType = (type) => {
  if(type === "MANAGER") {
    return (
      <Tag color="pink">
        소유자
      </Tag>
    )
  } else if(type === "SUB_MANAGER") {
    return (
      <Tag color="default">
        매니저
      </Tag>
    )
  }

  return undefined;
}


const tagByStatus = (status) => {
  if(status === "PENDING") {
    return (
      <Tag color="blue">
        pending
      </Tag>
    )
  } else if(status === "ACTIVATED") {
    return (
      <Tag color="default">
        activated
      </Tag>
    )
  }

  return undefined;
}


const AddAccountModal = ({ visible, setVisible, onOk }) => {
  const [form] = Form.useForm();
  const [processing, setProcessing] = useState(false);
  const [checkingShopName, setCheckingShopName] = useState(undefined);

  const onOkInner = () => {
    // onOk(form);
    // setVisible(false);
    setProcessing(true);

    const params = { ...form.getFieldsValue() };
    console.log("params: ", params)
    const shopId = params.shopId;

    axios.post(`/admin/sites/${shopId}/accounts`, qs.stringify(params)).then(res => {
      setProcessing(false);
      message.success("할당 성공")
      setVisible(false);
      window.location.reload(false);
    }).catch(() => {
      message.error("할당 실패");
      setProcessing(false);
    })
  };

  const onCheck = () => {
    const shopId = form.getFieldValue("shopId")
    axios.get(`/admin/sites/${shopId}`).then(res => {
      const shop = res.data.site;
      setCheckingShopName(shop.name);
    })
  }

  return (
    <Modal
      title="계정 추가"
      visible={visible}
      onOk={onOkInner}
      onCancel={() => setVisible(false)}
      width="400px"
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          닫기
        </Button>,
        <Button key="submit" type="primary" loading={processing} onClick={onOkInner}>
          추가
        </Button>,
      ]}
    >
      <Form
        layout="vertical"
        form={form}
        name="add-account-form"
        onFinish={undefined}
      >
        <Form.Item
          name="shopId"
          label="shopId"
          rules={[{ required: true }]}
        >
          <Space>
            <Input style={{ width: "80px" }} />
            <Button type="dashed" onClick={onCheck}>확인</Button>
            <span>{checkingShopName}</span>
          </Space>
        </Form.Item>
        <Form.Item
          name="authority"
          label="authority"
          rules={[{ required: true }]}
        >
          <Select>
            <Option value="MANAGER">MANAGER</Option>
            <Option value="SUB_MANAGER">SUB MANAGER</Option>
          </Select>
        </Form.Item>
        <Form.Item
          type="email"
          name="email"
          label="email"
          rules={[{ required: true, message: '추가할 계정의 email 주소를 입력해주세요.' }]}
        >
          <Input />
        </Form.Item>

      </Form>
    </Modal>
  )
};

export default inject("siteStore")(observer(ShopAccounts));