import React, { useState, useEffect, useRef } from "react";
import {useHistory, useLocation} from "react-router-dom";
import { inject, observer } from "mobx-react";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import qs from "query-string";
import {PageHeader, Tag, Button, Statistic, Descriptions, Row, Col, Card, Table, Tabs, Modal, Form,
  Input,
  Tooltip,
  Checkbox,
  Select,
  message,
  Space,
  DatePicker
} from 'antd';
import "./ShopItems.scss";
import ItemFilter from "./ItemFilter";
import PartnerItemDetailModal from "./PartnerItemDetailModal";

const ShopItems = ({ categoryStore, siteStore }) => {
  const [data, setData] = useState(undefined);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [showItemDetailModal, setShowItemDetailModal] = useState(false);

  const columns = [
    {
      title: '아이템코드',
      dataIndex: 'itemCode',
      key: 'itemCode',
    },
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      render: (status, row) => (
        <>
          <Tag key={1}>
            {status}
          </Tag>
        </>
      ),
    },
    {
      title: '이미지',
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      render: thumbnail => (<img src={`${thumbnail}`} style={{height: "30px"}}/>),
    },
    {
      title: '카테고리',
      dataIndex: 'categoryCode',
      key: 'categoryCode',
      render: categoryCode => (categoryStore.getCategoryNameWithDepth1(categoryCode))
    },
    {
      title: '상품명',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '원가',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: '할인가',
      dataIndex: 'discountPrice',
      key: 'discountPrice',
    },
    {
      title: '재고',
      dataIndex: 'hasStock',
      key: 'hasStock',
      render: hasStock => (hasStock ? "O" : "X")
    },
    {
      title: '등록일',
      dataIndex: 'createDate',
      key: 'createDate',
      render: createDate => moment(createDate).format("yyyy-MM-DD")
    },
  ];

  useEffect(() => {
    categoryStore.loadCategories();
    siteStore.loadSite();
  }, [])

  const fetch = param => {
    setLoading(true);

    param.page = page

    axios.get(`/partner/${siteStore.siteId}/items?${qs.stringify(param)}`).then(res => {
      const items = res.data;
      _.forEach(items, item => {
        item.key = item.id
      })
      setData(items);

      setLoading(false);
    }).catch(err => {
      message.error("불러오기 실패");
      setLoading(false);
    });
  }

  const onApplyFilter = filter => {
    const param = { ...filter }

    if(param.startDate)
      param.startDate = param.startDate.startOf("day");
    if(param.endDate)
      param.endDate = param.endDate.endOf("day");

    fetch(param)
  }

  if(!categoryStore.categories || !siteStore.site)
    return null

  const search = qs.parse(location.search)
  const { start_date, end_date, text } = search;

  return (
    <div className="ShopItems">
      <Card>
        <ItemFilter
          onSubmut={onApplyFilter}
          defaultStartDate={start_date}
          defaultEndDate={end_date}
          defaultText={text}
        />
        <Row gutter={[20, 20]}>
          <Col xs={24}>
            <Table
              columns={columns}
              dataSource={data}
              loading={loading}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    setSelectedItem(record)
                    setShowItemDetailModal(true)
                  }
                };
              }}
            />
          </Col>
        </Row>
      </Card>
      { showItemDetailModal && (
        <PartnerItemDetailModal
          visible={showItemDetailModal}
          setVisible={setShowItemDetailModal}
          selectedItem={selectedItem}
          onOk={() => {}}
        />
      )}
    </div>
  )
};

export default inject("categoryStore", "siteStore")(observer(ShopItems));