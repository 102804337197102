import { observable, action } from "mobx";
import axios from "axios";
import _ from "lodash";

class CategoryStore {
  @observable categories = undefined;
  @observable categoryTree = undefined;

  @action
  loadCategories = () => {
    if (!this.categories)
      return this.loadCategoriesForce();

    return new Promise(resolve => {
      resolve()
    });
  };

  @action
  loadCategoriesForce() {
    axios.get(`/admin/categories`).then(res => {
      const categories = res.data;
      const tree = categories.map(depth1 => this.unwarp(depth1));

      const newCategories = {};
      categories.forEach(depth1 => {
        newCategories[depth1.node.selfCode] = depth1.node;

        if(depth1.children) {
          depth1.children.forEach(depth2 => {
            newCategories[depth2.node.selfCode] = depth2.node;

            if(depth2.children) {
              depth2.children.forEach(depth3 => {
                newCategories[depth3.node.selfCode] = depth3.node;

                if(depth3.children) {
                  depth3.children.forEach(depth4 => {
                    newCategories[depth4.node.selfCode] = depth4.node;
                  })
                }
              })
            }
          })
        }
      });

      this.categories = newCategories;
      this.categoryTree = tree;
    });
  };

  @action
  addNewCategory(parentId, name, code) {
    const params = { parentId, name, code };
    return axios.post(`/admin/categories`, params).then(res => {
      this.loadCategories();
      return res;
    })
  }

  isReady() {
    return !!this.categories
  }

  unwarp(node) {
    let children = undefined;
    if(node.children && node.children.length > 0)
      children = node.children.map(child => this.unwarp(child));

    const categoryCode = node.node.selfCode;

    return {
      title: `${node.node.name}-${categoryCode}`,
      key: categoryCode,
      id: categoryCode,
      children
    }
  };


  getFullName = categoryCode => {
    const depth = getDepth(categoryCode);

    const depth1Code = getDepthCode(categoryCode, 1)
    const depth1 = this.categories[depth1Code]

    let fullName = depth1.name;

    if(depth >= 2) {
      const depth2Code = getDepthCode(categoryCode, 2)
      const depth2 = this.categories[depth2Code]
      fullName = `${fullName} - ${depth2.name}`
    }

    if(depth >= 3) {
      const depth3Code = getDepthCode(categoryCode, 3)
      const depth3 = this.categories[depth3Code]
      fullName = `${fullName} - ${depth3.name}`
    }

    return fullName
  }

  getCategoryNameWithDepth1 = categoryCode => {
    const depth = getDepth(categoryCode);

    const depth1Code = getDepthCode(categoryCode, 1)
    const depth1 = this.categories[depth1Code]

    let fullName = depth1.name;

    if(depth >= 1) {
      const depth2Code = getDepthCode(categoryCode, depth)
      const depth2 = this.categories[depth2Code]
      fullName = `${fullName} - ${depth2.name}`
    }

    return fullName
  }


}

export const getDepth = categoryCode => {
  if(!categoryCode)
    return undefined;

  const string = "" + categoryCode;

  if(_.endsWith(string, "000000"))
    return 1;
  else if(_.endsWith(string, "0000"))
    return 2;
  else if(_.endsWith(string, "00"))
    return 3;

  return 4;
};

export const getParentCode = categoryCode => {
  const depth = getDepth(categoryCode);

  if(depth === 1) {
    return 0
  } else if(depth === 2) {
    return getDepthCode(categoryCode, 1)
  } else if(depth === 3) {
    return getDepthCode(categoryCode, 2)
  } else if(depth === 4) {
    return getDepthCode(categoryCode, 3)
  }

  return undefined;
};

export const getDepthCode = (categoryCode, depth) => {
  if(depth === 1) {
    return Math.floor(categoryCode / 1000000) * 1000000
  } else if(depth === 2) {
    return Math.floor(categoryCode / 10000) * 10000
  } else if(depth === 3) {
    return Math.floor(categoryCode / 100) * 100
  } else if(depth === 4) {
    return categoryCode
  }
}

export default new CategoryStore();