import React, { useState, useEffect, useRef } from "react";
import { inject, observer } from "mobx-react";
import {useParams} from "react-router-dom";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import {
  PageHeader,
  Tag,
  Button,
  Statistic,
  Descriptions,
  Row,
  Col,
  Card,
  Table,
  Tabs,
  Modal,
  Form,
  Input,
  Tooltip,
  Checkbox,
  Select,
  message,
  Space,
  Typography, Divider
} from 'antd';
import ReactMarkdown from "react-markdown";
import {CheckOutlined} from "@ant-design/icons";
import "github-markdown-css";

const { Title } = Typography;
const { Column, ColumnGroup } = Table;

const ShopSupport = ({ categoryStore, siteStore }) => {
  const [notices, setNotices] = useState(undefined);
  const [inquiries, setInquiries] = useState(undefined);
  const [activeNotice, setActiveNotice] = useState(undefined);
  const [inquiryModal, setInquiryModal] = useState(false);

  useEffect(() => {
    categoryStore.loadCategories();
    siteStore.loadSite();

    loadNotices();
    loadInquiries();
  }, [])

  const loadNotices = () => {
    axios.get(`/partner/notices`).then(res => {
      const { data } = res;
      setNotices(data);
    });
  }

  const loadInquiries = () => {
    axios.get(`/partner/${siteStore.siteId}/inquiries`).then(res => {
      const inquiries = res.data;
      setInquiries(inquiries)
    });
  }

  const onInquiryRowClick = row => {
    Modal.info({
      title: row.title,
      content: (
        <div className="markdown">
          <ReactMarkdown source={row.contents} />
          <Divider>답변</Divider>
          { row.reply && (
            <ReactMarkdown source={row.reply} />
          )}
          { !row.reply && (
            <span>아직 답변이 작성되지 않았습니다.</span>
          )}
        </div>
      ),
      width: 640,
      onOk() {},
    });
  }

  const submitInquiry = form => {
    const param = { ...form.getFieldsValue() }
    axios.post(`/partner/${siteStore.siteId}/inquiries`, param).then(res => {
      loadInquiries();
    });
  }

  if(!categoryStore.categories || !siteStore.site)
    return null

  return (
    <div>
      <Row gutter={[20, 20]}>
        <Col xs={{ offset: 6, span: 12 }}>
          <Row gutter={[20, 20]}>
            <Col xs={24}>
              <Card>
                <Title level={4}>공지사항</Title>
                <Table
                  dataSource={notices}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: () => setActiveNotice(record)
                    };
                  }}
                >
                  <Column title="#" dataIndex="id" key="id" width={50} />
                  <Column
                    title="종류"
                    dataIndex="category"
                    key="category"
                    render={tag => (
                      <Tag color="blue" key={tag}>
                        {tag}
                      </Tag>
                    )}
                    width={100}
                  />
                  <Column title="제목" dataIndex="title" key="title" />
                  <Column
                    title="등록일"
                    dataIndex="createDate"
                    key="createDate"
                    render={date => (moment(date).format("yyyy-MM-DD"))}
                    width={120}
                  />
                </Table>
              </Card>
            </Col>
          </Row>
          <Row gutter={[20, 20]}>
            <Col xs={24}>
              <Card>
                <div>
                  <Space align="baseline" style={{ display: "flex", justifyContent: "space-between"}}>
                    <Title level={4}>1:1 문의</Title>
                    <Button type="primary" onClick={() => setInquiryModal(true)}>문의하기</Button>
                  </Space>
                </div>
                <Table
                  dataSource={inquiries}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: () => onInquiryRowClick(record)
                    };
                  }}
                >
                  <Column title="제목" dataIndex="title" key="title" />
                  <Column
                    title="답변"
                    dataIndex="reply"
                    key="reply"
                    render={reply => (
                      reply ? (
                        <Tag color="green" key={reply}>
                          <CheckOutlined />
                        </Tag>
                      ) : null
                    )}
                    width={60}
                  />
                  <Column
                    title="문의일"
                    dataIndex="createDate"
                    key="createDate"
                    render={date => (moment(date).format("yyyy-MM-DD"))}
                    width={120}
                  />
                </Table>
              </Card>
            </Col>
          </Row>
          <Row gutter={[20, 20]}>
            <Col xs={24}>
              <Card>
                <div>주식회사 체리픽</div>
                <div>사업자등록번호 320-86-01907</div>
                <div style={{ marginTop: 20 }}>
                  <Space size={16}>
                    <a
                      href="https://www.notion.so/leesh312/58dcd5fa5d5e4e0284495a74dfb9593c"
                      target="_blank"
                    >
                      체리픽 제휴 쇼핑몰 입점 이용약관
                    </a>
                    <a
                      href="https://www.notion.so/d73fdafde16e42dea7309a4a88612b19"
                      target="_blank"
                    >
                      개인정보 수집 및 이용에 대한 안내
                    </a>
                  </Space>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      { activeNotice && (
        <NoticeModal
          notice={activeNotice}
          onCancel={() => setActiveNotice(undefined)}
        />
      )}
      <InquiryModal
        visible={inquiryModal}
        setVisible={setInquiryModal}
        onOk={submitInquiry}
      />
    </div>
  )
};


const NoticeModal = ({ notice, onCancel }) => {
  return (
    <Modal
      title={notice.title}
      visible={true}
      width="600px"
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          닫기
        </Button>
      ]}
    >
      <div className="markdown-body">
        <ReactMarkdown source={notice.contents} />
      </div>
    </Modal>
  )
};

const InquiryModal = ({ visible, setVisible, onOk }) => {
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  const onOkInner = () => {
    onOk(form);
    setVisible(false);
  };

  return (
    <Modal
      title="1:1 문의 작성"
      visible={visible}
      onOk={onOkInner}
      onCancel={() => setVisible(false)}
      cancelText="닫기"
      width="600px"
    >
      <Form
        {...layout}
        form={form}
        name="inquiry-form"
        onFinish={undefined}
      >
        <Form.Item name="title" label="제목" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="contents" label="문의 내용" rules={[{ required: true }]}>
          <Input.TextArea rows={10} />
        </Form.Item>
      </Form>
    </Modal>
  )
};


export default inject("categoryStore", "siteStore")(observer(ShopSupport));