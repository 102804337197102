import React, { useState, useEffect, useRef } from "react";
import { inject, observer } from "mobx-react";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import loginStore from "../../../store/LoginStore";
import {PageHeader, Tag, Button, Statistic, Descriptions, Row, Col, Card, Table, Tabs, Modal, Form,
  Input,
  Tooltip,
  Checkbox,
  Select,
  message, List,
  Typography,
  Divider

} from 'antd';
import "./ShopDashboard.scss";
import {Link} from "react-router-dom";
import PartnerItemDetailModal from "../ShopItems/PartnerItemDetailModal";

const { Paragraph } = Typography;

const ShopDashboard = ({}) => {
  const [data, setData] = useState({});
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [showItemDetailModal, setShowItemDetailModal] = useState(false);

  useEffect(() => {
    const shopId = loginStore.shop.id;
    axios.get(`/partner/${shopId}/dashboard`).then(res => {
      const { data } = res;
      setData(data);
    }).catch(err => {
      message.error("대시보드 정보 불러오기 실패")
    })
  }, []);

  return (
    <div className="ShopDashboard">
      <Row>
        <Col xs={{ offset: 6, span: 12 }}>
          <Row gutter={20}>
            {/*<Col xs={4}>*/}
            {/*  <Card>*/}
            {/*    <Statistic*/}
            {/*      title="오늘의 전환"*/}
            {/*      value={data.todaysConversionCount}*/}
            {/*    />*/}
            {/*    <Divider />*/}
            {/*  </Card>*/}
            {/*</Col>*/}
            {/*<Col xs={4}>*/}
            {/*  <Card>*/}
            {/*    <Statistic*/}
            {/*      title="오늘의 좋아요"*/}
            {/*      value={data.todaysLikeCount}*/}
            {/*    />*/}
            {/*    <Divider />*/}
            {/*  </Card>*/}
            {/*</Col>*/}
            {/*<Col xs={4}>*/}
            {/*  <Card>*/}
            {/*    <Statistic*/}
            {/*      title="오늘의 노출"*/}
            {/*      value={data.todaysImpressionCount}*/}
            {/*    />*/}
            {/*    <Divider />*/}
            {/*  </Card>*/}
            {/*</Col>*/}
            <Col xs={12}>
              <Card>
                <Statistic
                  title="오늘 수집된 상품"
                  value={data.todaysScrapItems ? data.todaysScrapItems.length : 0}
                />
                <Divider />
                <Link to={`/shop/items?start_date=${moment().format("yyyy-MM-DD")}&end_date=${moment().format("yyyy-MM-DD")}`}>
                  전체보기
                </Link>
              </Card>
            </Col>
            <Col xs={12}>
              <Card>
                <Statistic
                  title="
              최근(일주일) 신상품"
                  value={data.weekScrapedItems ? data.weekScrapedItems.length : 0}
                />
                <Divider />
                <Link to={`/shop/items?start_date=${moment().subtract(6, 'day')}&end_date=${moment()}`}>
                  전체보기
                </Link>
              </Card>
            </Col>
          </Row>
          <Row gutter={20} style={{marginTop: "20px"}}>
            <Col xs={24}>
              <Card>
                추천 랭킹
                { data.rankingItems && (
                  <List
                    size="small"
                    bordered
                    dataSource={_.take(data.rankingItems, 20)}
                    renderItem={(item, index) => (
                      <List.Item>
                        <span style={{display: "inline-block", width: "40px"}}>{index + 1}.</span>
                        <span style={{display: "inline-block", width: "84px"}}>#{item.id}</span>
                        <Paragraph ellipsis style={{marginBottom: "0", width: "100%"}}>
                          {item.title}
                        </Paragraph>
                        <a onClick={() => {
                          setSelectedItem(item)
                          setShowItemDetailModal(true)
                        }}>
                          VIEW
                        </a>
                      </List.Item>
                    )}
                  />
                )}
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      { showItemDetailModal && (
        <PartnerItemDetailModal
          visible={showItemDetailModal}
          setVisible={setShowItemDetailModal}
          selectedItem={selectedItem}
          onOk={() => {}}
        />
      )}
    </div>
  )
};

export default inject("categoryStore")(observer(ShopDashboard));