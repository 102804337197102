import React, {useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import qs from "query-string";
import {Row, Col, Form, Input, Button, Checkbox, Card, Divider, Typography} from 'antd';
import {inject, observer} from "mobx-react";

const { Text, Title } = Typography;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 10 },
};

const AcquireAccount = ({ loginStore }) => {
  const location = useLocation();
  const history = useHistory();
  const [form] = Form.useForm();

  const search = qs.parse(location.search)
  const key = search && search.key

  useEffect(() => {
    console.log(key);
    axios.get(`/acquire-account?key=${key}`).then(res => {
      const { data } = res;
      const { shopName, email } = data;
      form.setFieldsValue({
        name: shopName,
        email
      });
    });
  }, [])

  const onSubmit = () => {
    const values = { ...form.getFieldsValue() }
    const { password, password2 } = values;

    if(password !== password2) {
      alert("비밀번호가 일치하지 않습니다.");
      return;
    }

    const data = new FormData();
    data.append("key", key);
    data.append("password", password);

    axios.put(`/acquire-account`, data).then(res => {
      alert("정상 처리되었습니다.")
      history.replace("/login");
    }).catch(res => {
      alert("죄송합니다. 오류가 발생하였습니다. 고객센터로 문의 바랍니다.")
    })
  }


  return (
    <div style={{ height: "100vh", paddingTop: "200px" }}>
      <Row>
        <Col span={10} offset={7}>
          <Card>
            <div style={{ textAlign: "center", padding: "24px" }}>
              <img src="/images/icon_bi_text.png" style={{ width: "160px", margin: "0 auto"}} />
              <Title level={3}>관리자 계정 획득</Title>
            </div>
            <Form
              {...layout}
              name="basic"
              onFinish={onSubmit}
              form={form}
              onFinishFailed={() => console.log("onFinishFailed")}
            >
              <Form.Item
                label="쇼핑몰"
                name="name"
              >
                <Input className="static" />
              </Form.Item>
              <Form.Item
                label="email"
                name="email"
              >
                <Input className="static" />
              </Form.Item>
              <Form.Item
                label="password"
                name="password"
                rules={[{ required: true, message: '비밀번호를 입력해주세요.' }]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label="re-password"
                name="password2"
                rules={[{ required: true, message: '비밀번호를 다시 입력해주세요.' }]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit" block >
                  확인
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>

  )
};

export default inject("loginStore")(observer(AcquireAccount));