import React, { useState, useEffect, useRef } from "react";
import { inject, observer } from "mobx-react";
import axios from "axios";
import _ from "lodash";
import {PageHeader, Tag, Button, Statistic, Descriptions, Row, Col, Card, Table, Tabs, Modal, Form,
  Input,
  Tooltip,
  Checkbox,
  Select,
  message
} from 'antd';
import { StackedColumn } from '@ant-design/charts';
import LikeDislikeConversion from "./LikeDislikeConversion";
import UserCount from "./UserCount";

const Dashboard = ({ categoryStore }) => {
  const [userStatics, setUserStatics] = useState(undefined);
  const [conversionStatics, setConversionStatics] = useState(undefined);

  useEffect(() => {

    axios.get(`/admin/statics/day-user`).then(res => {
      const data = res.data;
      setUserStatics(data)
    });

    axios.get(`/admin/statics/day-conversion`).then(res => {
      const data = res.data;
      setConversionStatics(data);

    })

  }, []);

  return (
    <div>
      <Row gutter={16}>
        <Col xs={12}>
          <Card>
            { userStatics && (
              <UserCount data={userStatics} />
            )}
          </Card>
        </Col>
        <Col xs={12}>
          <Card>
            { conversionStatics && (
              <LikeDislikeConversion data={conversionStatics} />
            )}
          </Card>
        </Col>
      </Row>
    </div>
  )
};

export default inject("categoryStore")(observer(Dashboard));