import React, {useRef, useState, useEffect} from "react";
import axios from "axios";
import {Button, Col, Divider, Form, Input, PageHeader, Row, Select, Card, Checkbox, Space, message, List, Upload, Typography, Spin} from "antd";
import { InboxOutlined } from '@ant-design/icons';
import querystring from "querystring";
import _ from "lodash";

const { Option } = Select;
const { Title } = Typography;

const NewStore = ({}) => {
  const [newShopForm] = Form.useForm();
  const [autoDetectForm] = Form.useForm();
  const [settingForm] = Form.useForm();
  const [resultForm] = Form.useForm();
  const [result, setResult] = useState({});
  const [shopId, setShopId] = useState(undefined);
  const [spinningDetectForm, setSpinningDetectForm] = useState(false);
  const [spinningSettingForm, setSpinningSettingForm] = useState(false);

  useEffect(() => {
    autoDetectForm.setFieldsValue({
      domain: "",
      categoryUrl: "",
      discountUrl: "",
      soldoutUrl: "",
    })
  }, []);

  const onSubmit = () => {
    const params = {...autoDetectForm.getFieldsValue(), ...settingForm.getFieldsValue() };
    let domain = params.domain;
    domain = domain.trim()
    if(_.endsWith(domain, "/")) {
      domain = domain.substring(0, domain.length - 1);
    }

    const categoryUrl = params.categoryUrl.replace(domain, "")
    const discountUrl = params.discountUrl.replace(domain, "")
    const soldoutUrl = params.soldoutUrl.replace(domain, "")

    params.domain = domain
    params.categoryUrl = categoryUrl
    params.discountUrl = discountUrl
    params.soldoutUrl = soldoutUrl

    autoDetectForm.setFieldsValue({
      domain,
      categoryUrl,
      discountUrl,
      soldoutUrl
    });

    setSpinningDetectForm(true);

    axios.post(`/admin/sites/autodetect`, params).then(res => {
      setSpinningDetectForm(false);

      const result = res.data;
      settingForm.setFieldsValue(result);
    }).catch(() => {
      setSpinningDetectForm(false);
      message.error("감지실패")
    })
  };

  const loadSetting = () => {
    const params = {...autoDetectForm.getFieldsValue() };
    let domain = params.domain;
    domain = domain.trim()
    if(_.endsWith(domain, "/")) {
      domain = domain.substring(0, domain.length - 1);
    }

    setSpinningDetectForm(true);

    axios.get(`/admin/sites/autodetect?domain=${domain}`).then(res => {
      setSpinningDetectForm(false);

      const result = res.data;
      settingForm.setFieldsValue(result);
    });
  }

  const onTestClick = () => {
    const params = {
      ...autoDetectForm.getFieldsValue(),
      ...settingForm.getFieldsValue()
    };

    setSpinningSettingForm(true);
    axios.post(`/admin/sites/test-crawling`, params).then(res => {
      setSpinningSettingForm(false);

      const { data } = res;
      setResult(data);

      const { itemCode, title, price, discountPrice, soldoutVerified, } = data;

      resultForm.setFieldsValue({
        itemCode,
        title,
        price,
        discountPrice,
        soldoutVerified
      });
    }).catch(() => {
      setSpinningSettingForm(false);
      message.error("테스크 크롤링 실패");
    });
  }

  const onSaveClick = () => {
    if(!confirm("저장하시겠습니까?"))
      return;

    const params = {
      ...autoDetectForm.getFieldsValue(),
      ...settingForm.getFieldsValue()
    };

    axios.put(`/admin/sites/settings`, params).then(res => {
      message.success("저장 완료")
      const shopId = res.data;
      setShopId(shopId);

      resultForm.setFieldsValue({
        shopId
      });
    }).catch(err => {
      message.error("저장 실패")
    });
  }

  const onSubmitNewShop = () => {
    const params = {
      ...newShopForm.getFieldsValue()
    }

    axios.post(`/admin/sites`, params).then(res => {
      message.info("생성 완료!")
    }).catch(() => {
      message.error("생성 실패!")
    })
  }

  const onInitialCrawlingClick = () => {
    if(!confirm("초기 크롤링 시작하시겠습니까?"))
      return;

    alert("조심!!")

    message.info("초기 크롤링 시작")
    axios.post(`/admin/sites/${shopId}/crawling/initialize`).then(res => {
    });
  }

  const onChangeUpload = (info, name) => {
    const { file } = info;
    const { response } = file;

    if(response) {
      console.log(name, response)
      newShopForm.setFieldsValue({
        [name]: response
      });
    }
  }

  return (
    <Row gutter={[8, 20]}>
      <Col xs={{ offset: 2, span: 20 }}>
        <Card>
          <Form
            labelCol={{
              sm: {
                span: 4,
              }
            }}
            wrapperCol={{
              sm: {
                span: 8,
              }
            }}
            form={newShopForm}
            name="newShopForm"
            onFinish={undefined}
            scrollToFirstError
          >
            <Form.Item
              name="name"
              label="쇼핑몰명"
              required={true}
            >
              <Input/>
            </Form.Item>
            <Form.Item
              name="nameEn"
              label="쇼핑몰명 영문"
              required={true}
            >
              <Input/>
            </Form.Item>
            <Form.Item
              name="domain"
              label="domain"
              required={true}
            >
              <Input/>
            </Form.Item>
            <Form.Item
              name="freeshipping"
              label="전상품무료배송"
              required={true}
            >
              <Input placeholder="true|false"/>
            </Form.Item>
            <Form.Item
              name="freeshippingPrice"
              label="무료배송최소금액"
              required={true}
            >
              <Input/>
            </Form.Item>
            <Form.Item
              name="shippingPrice"
              label="배송비"
              required={true}
            >
              <Input/>
            </Form.Item>
            <Form.Item
              name="logoUrl"
              label="로고"
            >
              <Form.Item noStyle>
                <Upload.Dragger
                  name="file"
                  action="http://localhost:8080/admin/upload?type=shop-logo"
                  headers={{
                    "X-Auth-Token": axios.defaults.headers.common['X-Auth-Token']
                  }}
                  listType="picture"
                  multiple={false}
                  onChange={info => onChangeUpload(info, "logoUrl")}
                >
                  <p className="ant-upload-drag-icon" style={{marginBottom: 0}}>
                    <InboxOutlined />
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>
            <Form.Item
              name="iconUrl"
              label="아이콘"
            >
              <Form.Item noStyle>
                <Upload.Dragger
                  name="file"
                  action="http://localhost:8080/admin/upload?type=shop-icon"
                  headers={{
                    "X-Auth-Token": axios.defaults.headers.common['X-Auth-Token']
                  }}
                  listType="picture"
                  multiple={false}
                  onChange={info => onChangeUpload(info, "iconUrl")}
                >
                  <p className="ant-upload-drag-icon" style={{marginBottom: 0}}>
                    <InboxOutlined />
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>
            <Form.Item wrapperCol={{ span: 20, offset: 4 }}>
              <Button
                type="primary"
                onClick={onSubmitNewShop}
              >
                저장
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
      <Col xs={{ offset: 2, span: 20 }}>
        <Spin spinning={spinningDetectForm}>
          <Card>
            <div>
            </div>
            <div style={{ marginTop: "20px" }}>
              <Form
                labelCol={{
                  sm: {
                    span: 4,
                  }
                }}
                wrapperCol={{
                  sm: {
                    span: 8,
                  }
                }}
                form={autoDetectForm}
                name="autoDetectForm"
                onFinish={onSubmit}
                scrollToFirstError
              >
                <Form.Item
                  name="domain"
                  label="도메인"
                  required={true}
                  rules={[{ required: true }]}
                >
                  <Input/>
                </Form.Item>
                <Form.Item
                  name="categoryUrl"
                  label="categoryUrl"
                  required={true}
                  rules={[{ required: true }]}
                >
                  <Input/>
                </Form.Item>
                <Form.Item
                  name="discountUrl"
                  label="discountUrl"
                  required={true}
                  rules={[{ required: true }]}
                >
                  <Input/>
                </Form.Item>
                <Form.Item
                  name="soldoutUrl"
                  label="soldoutUrl"
                >
                  <Input/>
                </Form.Item>
                <Form.Item wrapperCol={{ span: 8, offset: 4 }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                  >
                    감지
                  </Button>
                  <Button
                    onClick={loadSetting}
                  >
                    LOAD
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <Divider/>
            <div>
              <Form
                labelCol={{
                  sm: {
                    span: 8,
                  }
                }}
                wrapperCol={{
                  sm: {
                    span: 16,
                  }
                }}
                form={settingForm}
                name="settingForm"
                onFinish={onSubmit}
                scrollToFirstError
              >
                <Row gutter={[20, 10]}>
                  <Col xs={12}>
                    <Form.Item
                      name="solutionType"
                      label="solutionType"
                      required={true}
                      rules={[{ required: true }]}
                    >
                      <Input/>
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                  </Col>
                </Row>
                <Row gutter={[20, 10]}>
                  <Col xs={12}>
                    <Form.Item
                      name="pageFetchCommand"
                      label="pageFetchCommand"
                    >
                      <Input/>
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Title level={5}>pageFetchCommand</Title>
                    {`["GET", {"page": "{{page}}", "sort_method": "5"}]`}
                    <br/>
                    {`["GET", {"page": "{{page}}","sort":"order"}]`}
                    <br/>
                    {`["GET", {"page": "{{page}}","ordy":"1"}]`}
                  </Col>
                </Row>
                <Row gutter={[20, 10]}>
                  <Col xs={12}>
                    <Form.Item
                      name="itemCountInPage"
                      label="itemCountInPage"
                    >
                      <Input/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[20, 10]}>
                  <Col xs={12}>
                    <Form.Item
                      name="itemSelector"
                      label="itemSelector"
                      required={true}
                      rules={[{ required: true }]}
                    >
                      <Input/>
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Title level={5}>itemSelector</Title>
                    {`.xans-product-listnormal .thumbnail a`}
                  </Col>
                </Row>
                <Row gutter={[20, 10]}>
                  <Col xs={12}>
                    <Form.Item
                      name="itemCodeParseCommand"
                      label="itemCodeParseCommand"
                      required={true}
                      rules={[{ required: true }]}
                    >
                      <Input/>
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Title level={5}>itemCodeParseCommand</Title>
                    {`["GetByParam","product_no"]`} <br/>
                    {`["GetByParam","branduid"]`} <br/>
                    {`["GetByPath","2"]`} <br/>
                  </Col>
                </Row>
                <Row gutter={[20, 10]}>
                  <Col xs={12}>
                    <Form.Item
                      name="titleSelector"
                      label="titleSelector"
                      required={true}
                      rules={[{ required: true }]}
                    >
                      <Input/>
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Title level={5}>titleSelector</Title>
                    {`.headingArea h2`} <br/>
                    {`.infoArea .name`} <br/>
                  </Col>
                </Row>
                <Row gutter={[20, 10]}>
                  <Col xs={12}>
                    <Form.Item
                      name="descriptionSelector"
                      label="descriptionSelector"
                    >
                      <Input/>
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Title level={5}>descriptionSelector</Title>
                    {`.headingArea .topdesc`} <br/>
                  </Col>
                </Row>
                <Row gutter={[20, 10]}>
                  <Col xs={12}>
                    <Form.Item
                      name="priceParseCommand"
                      label="priceParseCommand"
                      required={true}
                      rules={[{ required: true }]}
                    >
                      <Input/>
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Title level={5}>priceParseCommand</Title>
                    {`["SelectorText","#span_product_price_text"]`} <br/>
                    {`["SelectorText","#span_product_price_custom strike"]`} <br/>
                  </Col>
                </Row>
                <Row gutter={[20, 10]}>
                  <Col xs={12}>
                    <Form.Item
                      name="discountPriceParseCommand"
                      label="discountPriceParseCommand"
                      required={true}
                      rules={[{ required: true }]}
                    >
                      <Input/>
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Title level={5}>discountPriceParseCommand</Title>
                    {`["SelectorText","#span_product_price_sale"]`} <br/>
                    {`["Disuse"]`} <br/>
                  </Col>
                </Row>
                <Row gutter={[20, 10]}>
                  <Col xs={12}>
                    <Form.Item
                      name="imageSelector"
                      label="imageSelector"
                      required={true}
                      rules={[{ required: true }]}
                    >
                      <Input/>
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Title level={5}>imageSelector</Title>
                    {`["GetElementAttribute","#prdDetail .cont img","src"]`} <br/>
                    {`["GetElementAttribute",".product_contents img","ec-data-src"]`} <br/>
                  </Col>
                </Row>
                <Row gutter={[20, 10]}>
                  <Col xs={12}>
                    <Form.Item
                      name="imageExcludeSelector"
                      label="imageExcludeSelector"
                    >
                      <Input/>
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Title level={5}>imageExcludeSelector</Title>
                    {`#prdDetail iframe`} <br/>
                    {`.det_view .guide_cnt`} <br/>
                  </Col>
                </Row>
                <Row gutter={[20, 10]}>
                  <Col xs={12}>
                    <Form.Item
                      name="soldoutCommand"
                      label="soldoutCommand"
                      required={true}
                      rules={[{ required: true }]}
                    >
                      <Input/>
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Title level={5}>soldoutCommand</Title>
                    {`["SelectorExist", ".headingArea img[alt='품절']"]`} <br/>
                    {`["SelectorExist", ".xans-product-action .sold:not(.displaynone)"]`} <br/>
                    {`["SelectorTextContains",".info .soldout","품절"]`} <br/>
                    {`["Disuse"]`} <br/>
                    {``} <br/>
                  </Col>
                </Row>
                <Row gutter={[20, 10]}>
                  <Col xs={12}>
                    <Form.Item
                      name="excludeFileNames"
                      label="excludeFileNames"
                    >
                      <Input/>
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Title level={5}>excludeFileNames</Title>
                    model11.jpg|model44.jpg|washingtips.jpg
                  </Col>
                </Row>
              </Form>
            </div>
          </Card>
        </Spin>
      </Col>
      <Col xs={{ offset: 2, span: 20 }}>
        <Spin spinning={spinningSettingForm}>
          <Card>
            <Row>
              <Space style={{ flex: "1" }}>
                <Button
                  onClick={onTestClick}
                >
                  테스트
                </Button>
              </Space>
              <Space>
                <Button
                  onClick={onSaveClick}
                  danger
                  type="primary"
                >
                  저장
                </Button>
                <Button
                  onClick={onInitialCrawlingClick}
                  danger
                  type="primary"
                >
                  초기 스크래핑
                </Button>
              </Space>
            </Row>
            <div style={{ marginTop: "20px" }}>
              <Form
                labelCol={{
                  sm: {
                    span: 4,
                  }
                }}
                wrapperCol={{
                  sm: {
                    span: 8,
                  }
                }}
                form={resultForm}
                name="resultForm"
              >
                <Form.Item
                  name="shopId"
                  label="shopId"
                >
                  <Input value={shopId} onChange={e => setShopId(e.target.value)}/>
                </Form.Item>
                <Form.Item
                  name="title"
                  label="title"
                >
                  <Input/>
                </Form.Item>
                <Form.Item
                  name="price"
                  label="price"
                >
                  <Input/>
                </Form.Item>
                <Form.Item
                  name="discountPrice"
                  label="discountPrice"
                >
                  <Input/>
                </Form.Item>
                <Form.Item
                  name="soldoutVerified"
                  label="soldoutVerified"
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
                { result.itemUrls && (
                  <List
                    size="small"
                    bordered
                    dataSource={result.itemUrls}
                    renderItem={url => (
                      <List.Item>
                        {url}
                      </List.Item>
                    )}
                  />
                )}
                <Row>
                  { result.images && result.images.map(url => (
                    <Col xs={6} key={url}>
                      <img src={url} style={{maxWidth: "100%"}} />
                    </Col>
                  ))}
                </Row>
              </Form>
            </div>
          </Card>
        </Spin>
      </Col>
    </Row>
  )
};

export default NewStore;