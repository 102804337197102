import React, { useState, useEffect, useRef } from "react";
import { inject, observer } from "mobx-react";
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import _ from "lodash";
import {
  PageHeader,
  Tag,
  Button,
  Statistic,
  Descriptions,
  Row,
  Col,
  Card,
  Table,
  Tabs,
  Modal,
  Form,
  Input,
  Tooltip,
  Checkbox,
  Select,
  message,
  Space
} from 'antd';
import RouteName from "../../../RouteNames";

const ShopCategory = ({ categoryStore, siteStore }) => {
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'categoryId',
      key: 'categoryId',
    },
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      render: (status, row) => (
        <>
          <Tag key={1}>
            {status}
          </Tag>
        </>
      ),
    },
    {
      title: '자사몰 카테고리',
      dataIndex: 'siteCategoryName',
      key: 'siteCategoryName',
    },
    {
      title: '체리픽 카테고리',
      dataIndex: 'categoryCode',
      key: 'categoryCode',
      render: (categoryCode) => <>{categoryStore.getFullName(categoryCode)}</>
    },
    {
      title: '상품수',
      dataIndex: 'displayCount',
      key: 'displayCount',
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
      render: (url) => (
        <a href={`${siteStore.site.domain}${url}`} target="_blank">
          {url}
        </a>
      )
    },
    {
      title: '리스트',
      dataIndex: 'categoryId',
      key: 'categoryId',
      render: (categoryId) => (
        <Link to={`${RouteName.shopItems}?text=categoryId:${categoryId}`}>
          <Button size="small">리스트</Button>
        </Link>
      )
    },
  ];

  useEffect(() => {
    categoryStore.loadCategories();
    siteStore.loadSite();
    fetch();
  }, [])

  const fetch = () => {
    setLoading(true);

    const siteId = siteStore.siteId;
    if(!siteId)
      return

    axios.get(`/admin/sites/${siteId}/categories`)
      .then(res => {
        const items = res.data;
        setData(items);
        setLoading(false);
      }).catch(err => {
        message.error("불러오기 실패");
        setLoading(false);
    })
  }

  const handleTableChange = (pagination, filters, sorter) => {
    // fetch();
  }

  if(!categoryStore.categories || !siteStore.site)
    return null

  return (
    <div className="ShopItems">
      <Row gutter={20}>
        <Col xs={{ offset: 2, span: 20 }}>
          <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
    </div>
  )
};

export default inject("categoryStore", "siteStore")(observer(ShopCategory));