import categoryStore from "./CategoryStore";
import loginStore from "./LoginStore";
import siteStore from "./SiteStore";

class RootStore {
  constructor() {
    this.categoryStore = categoryStore;
    this.loginStore = loginStore;
    this.siteStore = siteStore
  }
}

export default new RootStore();
