import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {inject, observer} from "mobx-react";
import RouteNames from "../../RouteNames";
import axios from "axios";

const NO_NEED_TO_LOGIN = {
  [RouteNames.login]: true,
  [RouteNames.acquireAccount]: true,
};

const AuthDaemon = ({ loginStore, siteStore }) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const hasToken = axios.defaults.headers.common['X-Auth-Token'];
    if(hasToken) {
      loginStore.ping()
        .then(res => {

        })
        .catch(err => {
          loginStore.deleteToken();
          history.replace("/login");
        })
    } else {
      const { pathname } = location;

      console.log("location", location)
      if(!NO_NEED_TO_LOGIN[pathname]) {
        if(!loginStore.session) {
          history.replace("/login");
        }
      }
    }
  }, []);


  return null;
};

export default inject("loginStore", "siteStore")(observer(AuthDaemon));